const { rgba, rgb } = require('polished')

const primary = '#0074a8'
const primary100 = rgba(0, 116, 168, 0.1)
const secondary = '#e6edf1'
const info = '#5E9CE5'
const darkGrey = '#4a4a4a'
const darkGrey500 = rgba(74, 74, 74, 0.5)
const whisper = '#e4e4e4'
const grey = '#878787'
const lightBlue = '#b7d6e4'
const silverGrey = '#c0c0c0'
const light = '#fff'
const beige = '#fff1b8'

let theme = {
  blue100: '#e7f2fd',
  blue150: `${rgba(183, 220, 255, 0.15)}`,
  blue200: `${rgba(183, 220, 255, 0.2)}`,
  blue300: `${rgba(183, 220, 255, 0.3)}`, // b7dcff
  blue600: `${rgba(183, 220, 255, 0.6)}`,
  blue700: '#b7dcff',
  blue: '#5E9CE5',
  danger: '#d74c4c',
  dark: '#222',
  dark100: `${rgba(0, 0, 0, 0.1)}`,
  dark300: `${rgba(0, 0, 0, 0.3)}`,
  dark500: `${rgba(0, 0, 0, 0.5)}`,
  darkGrey: darkGrey,
  grey,
  grey700: `${rgba(74, 74, 74, 0.7)}`,
  greyF9: '#f9f9f9',
  silverGrey,
  info: info, // (16, 94, 166)
  info200: `${rgba(16, 94, 166, 0.2)}`,
  info500: `${rgba(16, 94, 166, 0.5)}`,
  info900: '#4098ef',
  lightDark: '#333',
  lightGrey: '#d8d8d8',
  lightPink: '#ffeaea',
  light: light,
  primary: primary,
  red100: '#ffdad6',
  red500: `${rgba(243, 76, 57, 0.5)}`,
  red: `${rgb(243, 76, 57)}`,
  crimson: '#ce2512',
  secondary,
  warning: primary,
  yellow100: beige,
  yellow: '#ffc247',
  btn: {
    text: '#fff',
    bg: primary,
    bgHover: '#005378',
    outlineHover: primary100,
    linkHover: '#0085c1',
    linkColor: '#5E9CE5',
    info: info,
    infoHover: ` ${rgba(94, 156, 229, 0.5)}`,
  },
  modal: {
    mask: `${rgba(0, 44, 86, 0.7)}`,
    maskDarken: `${rgba(0, 44, 86, 0.9)}`,
    border: info,
    title: primary,
  },

  gray88: '#e0e0e0',
  whisper: whisper,
  table: {
    headBg: 'transparent',
    head: grey,
    shape: 'none',
    bg: '#fff',
    bgHover: '#fffcf1',
    border: whisper,
    borderBottom: whisper,
    borderBottomHover: '#5e9ce5',
    borderHover: '#5e9ce5',
    spacing: 0,
  },
  slider: info,
  sliderBg: `${rgba(182, 129, 22, 0.54)}`,
  pagination: primary,
  block: {
    text: grey,
    section: darkGrey,
    title: darkGrey,
    content: '#f4f3f3',
    borderBottom: info,
    borderRight: 'transparent',
  },
  sidemenu: primary,
  calendarIcon: grey,
  searchIcon: primary,
  schedule: {
    searchBtn: darkGrey,
    searchBtnBg: primary,
    searchBtnIcon: darkGrey,
    text: info,
    checkboxDisable: darkGrey,
  },
  font: {
    primary,
    secondary: grey,
  },
  icon: {
    primary: darkGrey500,
    primaryHover: darkGrey,
    secondary: '#fff',
  },
  menu: {
    hoverBg: lightBlue,
    activeBg: '#e6edf1',
  },
  lightBlue,
  checkbox: {
    border: '#c0c0c0',
    bg: '#dff5ff',
    text: darkGrey,
    indeterminate: primary,
  },
  darkGrey500,
  map: {
    rectangleOptions: {
      fillColor: `${rgba(255, 207, 110, 0.3)}`,
      strokeColor: '#b68116',
      strokeWeight: 2,
    },
  },
  tabs: {
    default: '#c0c0c0',
    active: primary,
    bg: '#fff',
  },
  tooltip: {
    color: darkGrey,
    bg: beige,
  },
  message: {
    color: darkGrey,
    bg: beige,
    fontSize: '16px',
    fontWeight: 500,
  },
  gamboge: '#b68116',
}

module.exports = theme
