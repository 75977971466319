import React from 'react'
import gql from 'graphql-tag'
import { useQuery } from '@apollo/react-hooks'
import {
  head,
  find,
  toPairs,
  forEach,
  pathOr,
  forEachObjIndexed,
  mergeAll,
  map,
} from 'ramda'
import { Select, SelectProps } from '@/components/form'
import useFieldDecorator from '@/hooks/useFieldDecorator'
const { compose } = require('ramda')

export type LightEnum = {
  key: string
  name: string
  type: number
  data: {
    value: string
    desc: string
  }[]
}

export type QueryLightEnum = {
  results: { data: LightEnum[] }
}

export const getLightEnumQuery = gql`
  query getLightEnum {
    results @rest(type: "[LightEnum]", path: "/options/light/static") {
      data @type(name: "LightEnum") {
        key
        name
        type
        data
      }
    }
  }
`

export const getPoleTypesQuery = gql`
  query getPoleTypes {
    results @rest(type: "[PoleType]", path: "/light/poleTypes") {
      code
      data @type(name: "PoleType") {
        typeNames
      }
    }
  }
`

export const getLightTypesQuery = gql`
  query getLightTypes {
    results @rest(type: "[LightType]", path: "/light/lightTypes") {
      code
      data @type(name: "LightType") {
        typeNames
      }
    }
  }
`

export const getLampInfosQuery = gql`
  query getLampInfos {
    results @rest(type: "LampInfo", path: "/light/lampInfos") {
      code
      data @type(name: "LampInfo") {
        oldLampWs
        oldLampTypes
        newLampTypes
      }
    }
  }
`

export const installationStatus = {
  0: '未換裝',
  1: '已換裝',
  2: '非權責不換裝',
  3: '未過保暫不換裝',
  4: '普查',
}

export const uploadStatus = {
  0: '未上傳',
  1: '已上傳',
  2: '準備中',
  3: '新增資料',
  4: '申覆',
  998: '照片錯誤',
  999: '資料錯誤',
}

export const UPLOADED = compose(
  head,
  find(([_, value]) => value === '已上傳'),
  toPairs
)(uploadStatus)

export const INSTALLED_STATUS = compose(
  head,
  find(([_, value]) => value === '已換裝'),
  toPairs
)(installationStatus)

export const useLightEnum = () => {
  const { data, loading, error } = useQuery<QueryLightEnum>(getLightEnumQuery, {
    fetchPolicy: 'cache-first',
  })

  const {
    activeStateEnumByKey,
    lampTypeEnumByKey,
    switchLaekageEnumByKey,
    controllerTypeEnumByKey,
    deviceTypeEnumByKey,
    lightStatusEnumByKey,
  } = React.useMemo(() => {
    let activeStateEnumByKey: { [key: string]: string } = {}
    let lampTypeEnumByKey: { [key: string]: string } = {}
    let switchLaekageEnumByKey: { [key: string]: string } = {}
    let controllerTypeEnumByKey: { [key: string]: string } = {}
    let deviceTypeEnumByKey: { [key: string]: string } = {}
    let lightStatusEnumByKey: { [key: string]: string } = {}

    if (data) {
      let lightEnum: { [key: string]: LightEnum } = {}

      const forEachKey = (path: string) => (fn: any) =>
        compose(forEach(fn), pathOr([], [path, 'data']))(lightEnum)

      compose(
        forEach((x: LightEnum) => (lightEnum[x.key] = x)),
        pathOr([], ['results', 'data'])
      )(data)
      // prettier-ignore
      forEachKey('ACTIVE_STATE')((x: any) => (activeStateEnumByKey[x.value] = x.desc))
      // prettier-ignore
      forEachKey('LAMP_TYPE')((x: any) => (lampTypeEnumByKey[x.value] = x.desc))
      // prettier-ignore
      forEachKey('SWITCH_LAEKAGE')((x: any) => (switchLaekageEnumByKey[x.value] = x.desc))
      // prettier-ignore
      forEachKey('CONTROLLER_TYPE')((x: any) => (controllerTypeEnumByKey[x.value] = x.desc))
      // prettier-ignore
      forEachKey('DEVICE_TYPE')((x: any) => (deviceTypeEnumByKey[x.value] = x.desc))
      // prettier-ignore
      forEachKey('LIGHT_STATUS')((x: any) => (lightStatusEnumByKey[x.value] = x.desc))

      //新增刪除狀態
      activeStateEnumByKey['-1'] = '刪除'
    }

    return {
      activeStateEnumByKey,
      lampTypeEnumByKey,
      switchLaekageEnumByKey,
      controllerTypeEnumByKey,
      deviceTypeEnumByKey,
      lightStatusEnumByKey,
    }
  }, [data])

  return {
    lightEnum: pathOr([], ['results', 'data'], data) as LightEnum[],
    activeStateEnumByKey,
    lampTypeEnumByKey,
    switchLaekageEnumByKey,
    controllerTypeEnumByKey,
    deviceTypeEnumByKey,
    lightStatusEnumByKey,
    loading,
    error,
  }
}

export const useLightTypes = () => {
  const { data, ...rest } = useQuery(getLightTypesQuery, {
    fetchPolicy: 'cache-first',
  })

  return {
    ...rest,
    lightTypeEnums: pathOr([], ['results', 'data', 0, 'typeNames'], data),
  }
}

export const usePoleTypes = () => {
  const { data, ...rest } = useQuery(getPoleTypesQuery, {
    fetchPolicy: 'cache-first',
  })

  return {
    ...rest,
    poleTypeEnums: pathOr([], ['results', 'data', 0, 'typeNames'], data),
  }
}

export const useLampInfos = () => {
  const { data, ...rest } = useQuery(getLampInfosQuery, {
    fetchPolicy: 'cache-first',
  })

  const { newLampTypes, oldLampTypes, oldLampWs } = pathOr(
    { newLampTypes: [], oldLampTypes: [], oldLampWs: [] },
    ['results', 'data'],
    data
  )

  const forEachKey = (field: string) =>
    compose(
      mergeAll,
      map((x: any) => ({ [x[field]]: x })),
      pathOr([], [])
    )

  return {
    ...rest,
    newLampTypes: newLampTypes as { newLampTypeId: number; typeName: string }[],
    oldLampTypes: oldLampTypes as { oldLampTypeId: number; typeName: string }[],
    oldLampWs: oldLampWs as { oldLampWId: number; wname: string }[],
    newLampTypeById: forEachKey('newLampTypeId')(newLampTypes),
    oldLampTypeById: forEachKey('oldLampTypeId')(oldLampTypes),
    oldLampWattById: forEachKey('oldLampWId')(oldLampWs),
  }
}
export const OldLampTypeSelect = React.forwardRef<typeof Select, SelectProps>(
  ({ getFieldDecorator, initialValue, ...props }: SelectProps, ref): any => {
    const { oldLampTypes } = useLampInfos()

    let wrapper = useFieldDecorator({
      field: 'oldTypeId',
      getFieldDecorator,
      initialValue,
    })

    return wrapper(
      <Select forwardRef={ref} placeholder="燈種" {...props}>
        {oldLampTypes.map(x => (
          <Select.Option key={x.oldLampTypeId} value={x.oldLampTypeId}>
            {x.typeName}
          </Select.Option>
        ))}
      </Select>
    )
  }
)

export const NewLampTypeSelect = React.forwardRef<typeof Select, SelectProps>(
  ({ getFieldDecorator, initialValue, ...props }: SelectProps, ref): any => {
    const { newLampTypes } = useLampInfos()

    let wrapper = useFieldDecorator({
      field: 'newTypeId',
      getFieldDecorator,
      initialValue,
    })

    return wrapper(
      <Select forwardRef={ref} placeholder="燈種" {...props}>
        {newLampTypes.map(x => (
          <Select.Option key={x.newLampTypeId} value={x.newLampTypeId}>
            {x.typeName}
          </Select.Option>
        ))}
      </Select>
    )
  }
)

export const OldWattSelect = React.forwardRef<typeof Select, SelectProps>(
  ({ getFieldDecorator, initialValue, ...props }: SelectProps, ref): any => {
    const { oldLampWs } = useLampInfos()

    let wrapper = useFieldDecorator({
      field: 'oldWId',
      getFieldDecorator,
      initialValue,
    })

    return wrapper(
      <Select forwardRef={ref} placeholder="瓦特數" {...props}>
        {oldLampWs &&
          oldLampWs.map(x => (
            <Select.Option key={x.oldLampWId} value={x.oldLampWId}>
              {x.wname} W
            </Select.Option>
          ))}
      </Select>
    )
  }
)

export const LightTypeSelect = React.forwardRef<typeof Select, SelectProps>(
  ({ getFieldDecorator, initialValue, ...props }: SelectProps, ref): any => {
    const { lightTypeEnums } = useLightTypes()

    let wrapper = useFieldDecorator({
      field: 'lampStyle',
      getFieldDecorator,
      initialValue,
    })

    return wrapper(
      <Select forwardRef={ref} placeholder="選擇路燈型態" {...props}>
        {lightTypeEnums.map((i, idx) => (
          <Select.Option key={`lighttype${idx}`} value={i}>
            {i}
          </Select.Option>
        ))}
      </Select>
    )
  }
)

export const PoleTypeSelect = React.forwardRef<typeof Select, SelectProps>(
  ({ getFieldDecorator, initialValue, ...props }: SelectProps, ref): any => {
    const { poleTypeEnums } = usePoleTypes()

    let wrapper = useFieldDecorator({
      field: 'poleType',
      getFieldDecorator,
      initialValue,
    })

    return wrapper(
      <Select forwardRef={ref} placeholder="選擇燈桿材質" {...props}>
        {poleTypeEnums.map((i, idx) => (
          <Select.Option key={`poletype${idx}`} value={i}>
            {i}
          </Select.Option>
        ))}
      </Select>
    )
  }
)

export const ActiveStateSelect = React.forwardRef<typeof Select, SelectProps>(
  ({ getFieldDecorator, initialValue, ...props }: SelectProps, ref): any => {
    const { activeStateEnumByKey } = useLightEnum()

    let wrapper = useFieldDecorator({
      field: 'active',
      getFieldDecorator,
      initialValue,
    })

    return wrapper(
      <Select forwardRef={ref} allowClear placeholder="選擇啟用狀態" {...props}>
        {Object.keys(activeStateEnumByKey)
          .filter(i => i !== '-1')
          .map((x: string) => (
            <Select.Option key={x} value={Number(x)}>
              {activeStateEnumByKey[x]}
            </Select.Option>
          ))}
      </Select>
    )
  }
)

export const LampTypeSelect = React.forwardRef<typeof Select, SelectProps>(
  ({ getFieldDecorator, initialValue, ...props }: SelectProps, ref): any => {
    const { lampTypeEnumByKey } = useLightEnum()

    let wrapper = useFieldDecorator({
      field: 'lampType',
      getFieldDecorator,
      initialValue,
    })

    return wrapper(
      <Select forwardRef={ref} placeholder="選擇燈具種類" {...props}>
        {Object.keys(lampTypeEnumByKey).map(i => (
          <Select.Option key={i} value={Number(i)}>
            {lampTypeEnumByKey[i]}
          </Select.Option>
        ))}
      </Select>
    )
  }
)

export const SwitchLaekageSelect = React.forwardRef<typeof Select, SelectProps>(
  ({ getFieldDecorator, initialValue, ...props }: SelectProps, ref): any => {
    const { switchLaekageEnumByKey } = useLightEnum()

    let wrapper = useFieldDecorator({
      field: 'switchLeakage',
      getFieldDecorator,
      initialValue,
    })

    return wrapper(
      <Select forwardRef={ref} allowClear placeholder="選擇漏電開關" {...props}>
        {Object.keys(switchLaekageEnumByKey).map(i => (
          <Select.Option key={i} value={i}>
            {switchLaekageEnumByKey[i]}
          </Select.Option>
        ))}
      </Select>
    )
  }
)

export const ControllerTypeSelect = React.forwardRef<
  typeof Select,
  SelectProps
>(({ getFieldDecorator, initialValue, ...props }: SelectProps, ref): any => {
  const { controllerTypeEnumByKey } = useLightEnum()

  let wrapper = useFieldDecorator({
    field: 'controllerType',
    getFieldDecorator,
    initialValue,
  })

  return wrapper(
    <Select forwardRef={ref} placeholder="選擇通訊方式" {...props}>
      {Object.keys(controllerTypeEnumByKey).map(i => (
        <Select.Option key={i} value={Number(i)}>
          {controllerTypeEnumByKey[i]}
        </Select.Option>
      ))}
    </Select>
  )
})

export const LightStatusSelect = React.forwardRef<typeof Select, SelectProps>(
  ({ getFieldDecorator, initialValue, ...props }: SelectProps, ref): any => {
    const { lightStatusEnumByKey } = useLightEnum()

    let wrapper = useFieldDecorator({
      field: 'deviceStatus',
      getFieldDecorator,
      initialValue,
    })

    return wrapper(
      <Select forwardRef={ref} placeholder="選擇設備狀態" {...props}>
        {Object.keys(lightStatusEnumByKey).map(i => (
          <Select.Option key={i} value={Number(i)}>
            {lightStatusEnumByKey[i]}
          </Select.Option>
        ))}
      </Select>
    )
  }
)

export const InstallationStatusSelect = React.forwardRef<
  typeof Select,
  SelectProps
>(
  (
    { getFieldDecorator, initialValue, render, ...props }: SelectProps,
    ref
  ): any => {
    let wrapper = useFieldDecorator({
      field: 'installStatus',
      getFieldDecorator,
      initialValue,
    })

    const options: JSX.Element[] = []

    forEachObjIndexed((value: string, key: number) => {
      options.push(
        <Select.Option key={key + value} value={`installStatus:${key}`}>
          {value}
        </Select.Option>
      )
    }, installationStatus)

    forEachObjIndexed((value: string, key: number) => {
      options.push(
        <Select.Option key={key + value} value={`toGovStatus:${key}`}>
          {value}
        </Select.Option>
      )
    }, uploadStatus)

    return wrapper(
      <Select forwardRef={ref} placeholder="選擇換裝狀態" {...props}>
        {render ? render(toPairs(installationStatus)) : options}
      </Select>
    )
  }
)

export default useLightEnum
