import gql from 'graphql-tag'

export type MaintainState = {
  code: string
  isdisplay: boolean
  isenable: boolean
  remark?: string
  sorder: number
  state: string
}

export const maintainStateFragment = gql`
  fragment maintainStateInfo on MaintainState {
    code
    isdisplay
    isenable
    remark
    sorder
    state
    type
  }
`

export default maintainStateFragment
