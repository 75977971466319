import React from 'react'
import cx from 'classnames'
import styled, { css } from 'styled-components'
import { GetFieldDecorator, SetFieldsValue } from './types'

export const Asterisk = ({ className }: { className?: string }) => (
  <span
    className={cx('text-danger ml-2', className)}
    css={{ position: 'relative', top: 3 }}>
    *
  </span>
)

export const RequiredFieldsTooltip = ({
  desc,
}: {
  desc?: string | React.ReactNode
}) => (
  <div className="text-dark mb-6">
    <span className="text-danger" css={{ position: 'relative', top: 3 }}>
      *
    </span>{' '}
    必填欄位
    {desc}
  </div>
)

type AddressFormFieldProps<T> = {
  isEdit: boolean
  item: T | null | undefined
  getFieldDecorator: GetFieldDecorator
  setFieldsValue: SetFieldsValue
  distCol?: number
  addressFieldName?: string
  useDist?: boolean
  useVillage?: boolean
  dataFieldName?: string
  align?: 'middle' | 'top' | 'stretch' | 'bottom'
  className?: string
  label?: boolean
  addressClassName?: string
}

export const Flex = styled.div.attrs((props: any) => ({
  center: props.center,
  middle: props.middle,
}))`
  display: flex;

  ${p =>
    p.middle &&
    css`
      align-items: center;
    `};

  ${p =>
    p.center &&
    css`
      justify-content: center;
    `};
`
