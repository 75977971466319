import gql from 'graphql-tag'

export const streetLightFramgment = gql`
  fragment streetLightInfo on StreetLight {
    address
    circuit
    installTime
    lampCount
    lat
    lng
    lightId
    lightNo
    lightType
    lightMask
    poleHeight
    poleType
    power
    remark
    sessionNo
    switchBoxNo
    switchLeakage
    toGnd
    installStatus
    lamp
    userId
    pwrCoor
    pwrNo
    pwrApplyNo
    pwrApplyDep
    pwrFinishDate
    pwrApplyDate
    resistanceGnd
    resistanceIns
    powerCode
    toGovStatus
    toGovErrMsg
    villageNo
    caseA
    maintainCover
    pwrPoleNo
    pwrPoleArea
    roadDistance
  }
`
