import React from 'react'
import styled from 'styled-components'
import { Checkbox } from 'antd'
import { CheckboxProps } from 'antd/lib/checkbox'
import { RefProps } from './types'

type Props = CheckboxProps & { css?: React.CSSProperties }

const StyledCheckbox = styled((props: Props) => <Checkbox {...props} />)`
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: ${p => p.theme.checkbox.bg};
    border-color: ${p => p.theme.checkbox.border};
  }

  &.ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner,
  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: ${p => p.theme.checkbox.border};
  }

  .ant-checkbox-checked .ant-checkbox-inner:after {
    border: 2px solid ${p => p.theme.darkGrey};
    border-top: 0;
    border-left: 0;
  }

  .ant-checkbox-inner {
    border: 1px solid ${p => p.theme.checkbox.border};
  }

  &.is--normal {
    font-size: 14px !important;
    font-weight: normal !important;
  }
`

const CheckboxComponent: React.ElementType<Props> = React.forwardRef<
  typeof StyledCheckbox,
  RefProps & CheckboxProps & { css?: React.CSSProperties }
>(({ ...props }, ref) => <StyledCheckbox forwardRef={ref} {...props} />)

const CheckboxGroup = Checkbox.Group

export { CheckboxComponent as Checkbox, CheckboxGroup }
