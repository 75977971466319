import React from 'react'
import { pathOr } from 'ramda'
import { Icon, Divider } from 'antd'
import { useVillages, useAreas, Area as Areas } from '@/graphql/sessions'
import { Select } from './Select'
import { GetFieldDecorator } from './types'

export type AreaProps = {
  getFieldDecorator?: GetFieldDecorator
  labelCol?: number // FIXME: remove
  wrapperCol?: number // FIXME: remove
  onDistChange?: (id: string) => void
  onChange?: (id: number) => void
  style?: React.CSSProperties // FIXME: remove
  areaFieldName?: string
  useVillage?: boolean
  useDist?: boolean
  areaClassName?: string
  label?: boolean // FIXME: remove
  address?: boolean
  addressClassName?: string
  size?: 'default' | 'small' | 'large'
  customizeProps?: (props: AreaProps) => AreaProps
  onEditModalVisible?: () => void
  newItem?: object //TODO
  disabled?: boolean
  options?: object
  placeholder?: string
  sessionNo?: string
}

export const Area = React.forwardRef<typeof Select, AreaProps>(
  (
    {
      getFieldDecorator,
      onDistChange,
      onChange,
      areaFieldName = 'sessionName',
      useDist = true,
      label = false,
      size = 'default',
      // customizeProps,
      areaClassName,
      onEditModalVisible,
      newItem,
      disabled = false,
      options,
      placeholder = '選擇村里',
      style,
      sessionNo,
    }: AreaProps,
    ref
  ): any => {
    const { areaEnums, loading } = useAreas()
    const { distById, distEnums } = useVillages()

    let wrapper = React.useMemo(
      () =>
        getFieldDecorator
          ? getFieldDecorator
          : (field: string) => (node: React.ReactNode) => node,
      [getFieldDecorator]
    )
    //新增村里
    // React.useEffect(() => {
    //   if (newItem) {
    //     console.log('newItem', newItem)
    //   }
    // }, [newItem])

    const handleChange = (value: any) => {
      if (onChange) {
        onChange(value)
      }
      if (onDistChange) {
        onDistChange(value)
      }
    }

    let areas = React.useMemo(() => {
      const sessionId = pathOr('', [sessionNo as any, 'sessionId'], distById)
      return areaEnums.filter(x => x.sessionId == parseInt(sessionId))
    }, [sessionNo, distEnums, areaEnums])

    return (
      <>
        {useDist &&
          wrapper(
            areaFieldName,
            options
          )(
            <Select
              disabled={disabled}
              forwardRef={ref}
              loading={loading}
              style={style}
              className={areaClassName}
              placeholder={placeholder}
              allowClear
              size={size}
              onChange={handleChange}
              dropdownRender={menu => (
                <div>
                  {menu}
                  {onEditModalVisible && (
                    <>
                      <Divider style={{ margin: '4px 0' }} />
                      <div
                        style={{ padding: '0px 5px 4px', cursor: 'pointer' }}
                        onClick={() => onEditModalVisible()}
                        onMouseDown={e => e.preventDefault()}>
                        <Icon type="plus" /> 新增村里
                      </div>
                    </>
                  )}
                </div>
              )}>
              {areas.map(x => (
                <Select.Option key={x.villageNo} value={x.villageNo}>
                  {x.villageName}
                </Select.Option>
              ))}
            </Select>
          )}
      </>
    )
  }
)

export default Area
