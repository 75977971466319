import { ApolloClient } from 'apollo-client'
import { ApolloLink } from 'apollo-link'
import { onError, ErrorResponse } from 'apollo-link-error'
import { setContext } from 'apollo-link-context'
import { RestLink } from 'apollo-link-rest'
import { typePatcher } from 'apollo-type-patcher'
import logger from 'apollo-link-logger'
import { message as antMessage } from 'antd'
import Store from 'store'
import {
  path as Rpath,
  pathOr,
  ifElse,
  toLower,
  isNil,
  identity,
  invoker,
  __,
  always,
} from 'ramda'
import {
  InMemoryCache,
  IntrospectionFragmentMatcher,
} from 'apollo-cache-inmemory'
import { LogoutFn } from '@/hooks/useAuth'
import message from '@/utils/message'
import camelCase from '@/utils/camelcase'
import { envStore as env } from '@/env'
import { downloadFile, downloadCSV, hasToken } from '@/utils/webHelper'
import { User } from './auth'
import typeDefinitions from './typeDefinitions'
const { compose } = require('ramda')

type Props = { logout: LogoutFn; user: User }

const contentTypeIs = (s: string) => {
  return compose(
    ifElse(isNil, identity, (c = '') => c.includes(s)),
    invoker(1, 'get')('content-type'),
    Rpath(['headers'])
  )
}

const createClient = ({ logout, user }: Props) => {
  const fragmentMatcher = new IntrospectionFragmentMatcher({
    introspectionQueryResultData: {
      __schema: {
        types: [], // no types provided
      },
    },
  })

  const restLink = new RestLink({
    uri: env.apiBaseUrl,
    endpoints: {
      admin: env.adminApiBaseUrl,
      geocoding: env.googleMapsGeocodingApiBaseUrl,
      fake: env.fakeApiBaseUrl,
    },
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
    },
    fieldNameNormalizer: (key: string) => camelCase(key),
    responseTransformer: async (response: Response, typeName) => {
      if (!response || !response.headers) {
        return response
      }

      try {
        return contentTypeIs('sheet')(response)
          ? response.blob().then(blob => {
              downloadFile({ blob, filename: typeName })
              return response
            })
          : contentTypeIs('text/csv')(response)
          ? response.text().then(text => {
              downloadCSV({ text, filename: typeName })
              return response
            })
          : response.json().then(json => json)
      } catch (error) {
        console.error(error)
        return {}
      }
    },
    bodySerializers: {
      form: (data: any, headers: Headers) => {
        const formData = new FormData()
        for (let key in data) {
          if (data.hasOwnProperty(key)) {
            formData.append(key, data[key])
          }
        }

        headers.delete('Content-Type')

        return { body: formData, headers }
      },
    },
    typePatcher: typePatcher(typeDefinitions),
  })

  const authLink = setContext((_, { headers }) => {
    // get the authentication token from cookies if it exists
    // return the headers to the context so httpLink can read them
    const authHeaders = compose(
      () => {
        return ifElse(
          isNil,
          always({}),
          always({
            'X-Auth-Token': Store.get('scntpc_token'),
            'X-Auth-Nonce': Store.get('scntpc_nonce'),
          })
        )(Store.get('scntpc_token'))
      },
      toLower,
      pathOr('', ['operationName'])
    )(_)

    return {
      headers: {
        ...headers,
        ...authHeaders,
      },
    }
  })

  const errorLink = onError(
    ({ graphQLErrors, networkError }: ErrorResponse) => {
      if (graphQLErrors) {
        graphQLErrors.map(({ message, locations, path }) =>
          console.warn(
            `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
          )
        )
      }

      if (networkError) {
        console.warn(`[Network error]: ${networkError}`)

        const statusCode = Rpath(['statusCode'], networkError)
        const msg = pathOr('', ['result', 'message'], networkError)

        const isExpired =
          msg.includes('Unauthorized') ||
          msg.includes('JWT expired') ||
          msg.includes('無法解析 token')

        if (!isExpired && (statusCode === 500 || statusCode === 400)) {
          message({
            content:
              Rpath(['result', 'message'], networkError) ||
              Rpath(['result', 'error', 'message'], networkError) ||
              '伺服器錯誤',
            type: 'error',
            top: 50,
          })
        }

        if (isExpired && hasToken(user)) {
          logout({
            onCompleted: () =>
              message({
                content: '登入已過期，請重新登入',
                maxCount: 1,
                type: 'error',
                top: 100,
                onClose: antMessage.destroy,
              }),
          })
        }
      }
    }
  )

  return new ApolloClient({
    link: ApolloLink.from([
      ...(env.isEnvDev ? [logger] : []),
      authLink,
      errorLink,
      restLink,
    ]),
    cache: new InMemoryCache({ fragmentMatcher }),
    connectToDevTools: env.isEnvDev,
  })
}

export default createClient
