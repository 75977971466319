import React from 'react'
import { AlarmModes } from 'shared/graphql/alarm'
import { DistrictInfo } from 'shared/graphql/dashboard'

type ContextState = {
  handleCloseAlarmDrawer(): any
  handleOpenAlarmDrawer(): any
  handleToggleAlarmDrawer(data: Partial<DistrictInfo>): any
  handleModeChange(mode: AlarmModes): () => any
  setRectangle: React.Dispatch<
    React.SetStateAction<google.maps.Rectangle | null>
  >
  alarMode: AlarmModes
  setAlarmMode: React.Dispatch<React.SetStateAction<AlarmModes>>
  setIsClusterMode: React.Dispatch<React.SetStateAction<boolean>>
  isClusterMode: boolean
  visible: boolean
  rectangle: google.maps.Rectangle | null
}

const AlarmDrawerContext = React.createContext<ContextState>({} as ContextState)

export function AlarmProvider(props: any) {
  const [alarmDrawerVisible, setAlarmDrawerVisible] = React.useState(false)

  const [alarMode, setAlarmMode] = React.useState<AlarmModes>(AlarmModes.ALL)

  const [
    rectangle,
    setRectangle,
  ] = React.useState<google.maps.Rectangle | null>()

  const [isClusterMode, setIsClusterMode] = React.useState(true)

  const handleModeChange = React.useCallback(
    (mode: AlarmModes) => () => {
      setAlarmMode(mode)
      setIsClusterMode(true)
      setAlarmDrawerVisible(false)
    },
    []
  )

  const handleToggleAlarmDrawer = React.useCallback(
    ({ type: alarmType, id }: Partial<DistrictInfo>) => {
      setIsClusterMode(false)

      if (
        alarmType !== AlarmModes.NORMAL &&
        alarmType !== AlarmModes.MAINTAIN &&
        alarmType !== AlarmModes.ALL
      ) {
        setAlarmDrawerVisible(prev => !prev)
      }

      if (
        !alarmDrawerVisible &&
        (alarmType === AlarmModes.ALARM || alarmType === AlarmModes.ABNORMAL)
      ) {
        setIsClusterMode(false)
      }
    },
    [alarmDrawerVisible]
  )

  const handleOpenAlarmDrawer = React.useCallback(() => {
    setAlarmDrawerVisible(true)
  }, [])

  const handleCloseAlarmDrawer = React.useCallback(() => {
    setAlarmDrawerVisible(false)
  }, [])

  const value = React.useMemo(
    () => ({
      alarMode,
      setAlarmMode,
      handleOpenAlarmDrawer,
      handleCloseAlarmDrawer,
      handleModeChange,
      handleToggleAlarmDrawer,
      isClusterMode,
      setIsClusterMode,
      rectangle,
      setRectangle,
      visible: alarmDrawerVisible,
    }),
    [
      alarMode,
      setAlarmMode,
      alarmDrawerVisible,
      handleOpenAlarmDrawer,
      handleCloseAlarmDrawer,
      setIsClusterMode,
      handleModeChange,
      handleToggleAlarmDrawer,
      isClusterMode,
      rectangle,
      setRectangle,
    ]
  )

  return <AlarmDrawerContext.Provider value={value} {...props} />
}

export function useAlarmDrawer() {
  const context = React.useContext(AlarmDrawerContext)
  if (context === undefined) {
    throw new Error('useDrawerState must be used within a DrawerProvider')
  }
  return context
}

export default useAlarmDrawer
