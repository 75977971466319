import React from 'react'
import gql from 'graphql-tag'
import { ApolloError } from 'apollo-client'
import { pathOr, path, isEmpty } from 'ramda'
import { useQuery } from '@/hooks/useQuery'
import { ApolloQueryVariables } from '@/constants/types'
import { useMutation, MutationOptions } from '@/hooks/useMutation'
import { useMutation as useApolloMutation } from '@apollo/react-hooks'
import message from '@/utils/message'
import { publicUrl } from '@/env'

type WebSetting = {
  fileHash: string
  filePash: string
  id: number
  name: string
  url: string
}

type ImageProp = {
  hash: string
  path: string
  url: string
}

export type QueryWebResult = { response: { data: WebSetting } }

export const getWebSettingQuery = gql`
  query getWebSetting {
    results @rest(type: "WebSetting", path: "/system/logo") {
      data @type(name: WebSetting) {
        logoUrl
        siteName
      }
    }
  }
`

export const getProjectInfoQuery = gql`
  query getProjectInfo {
    results @rest(type: "ProjectInfo", path: "/project/projectInfo") {
      data @type(name: ProjectInfo) {
        projectId
        projectName
        maxLampCount
        maxLightLength
        isLightLengthFix
        minPictureNeed
        isControllerEnable
        isLampCodeEnable
        isPowerCodeEnable
      }
    }
  }
`

export const getPictureInfoQuery = gql`
  query getPictureInfo {
    results @rest(type: "PictureInfo", path: "/light/pictureInfos") {
      data @type(name: PictureInfo) {
        displayName
        pictureInfoId
        pictureInfoSeq
      }
    }
  }
`

export const createWebSettingQuery = gql`
  mutation createWebSetting($input: Alarm) {
    results(input: $input)
      @rest(type: "Alarm", method: "POST", path: "/system/profile") {
      data
      errorMsg
    }
  }
`

export const uploadImageQuery = gql`
  mutation uploadImage($input: Input!) {
    results(input: $input)
      @rest(
        type: "ImageProp"
        path: "/upload/image"
        method: "POST"
        bodySerializer: "form"
      ) {
      data @type(name: "ImageProp") {
        hash
        path
        url
      }
      errorMsg
    }
  }
`

type QueryResult = { siteName: string; logoUrl: string } | undefined

export function useQueryWebSetting(variables?: ApolloQueryVariables) {
  const { data, ...others } = useQuery(getWebSettingQuery, {
    fetchPolicy: 'cache-first',
  })

  const dataSource = React.useMemo(() => {
    if (!data || isEmpty(pathOr({}, ['results', 'data'], data))) {
      return {
        fileHash: '',
        filePash: '',
        name: '',
        url: '',
      } as WebSetting
    }

    const _data: QueryResult = path(['results', 'data'], data)

    return {
      fileHash: '',
      filePash: '',
      name: _data?.siteName,
      url: _data?.logoUrl,
    }
  }, [data])

  return {
    web: {
      ...dataSource,
      // name: '換裝系統',
      // url: `${publicUrl}/images/logo.svg`,
    },
    ...others,
  }
}

export function useProjectInfo() {
  const { data, ...others } = useQuery(getProjectInfoQuery, {
    fetchPolicy: 'cache-first',
  })

  let _data = pathOr({ maxLampCount: 0 }, ['results', 'data'], data) as {
    projectId: number
    projectName: string
    maxLampCount: number
    maxLightLength: number
    isLightLengthFix: boolean
    minPictureNeed: number
    isControllerEnable: boolean
    isLampCodeEnable: boolean
    isPowerCodeEnable: boolean
  }

  return {
    data: _data,
    info: _data,
    ...others,
  }
}

export function usePictureInfo() {
  const { data, ...others } = useQuery(getPictureInfoQuery, {
    fetchPolicy: 'cache-first',
  })

  let _data = pathOr([], ['results', 'data'], data) as {
    displayName: string
    pictureInfoId: number
    pictureInfoSeq: string
  }[]

  return {
    data: _data,
    info: _data,
    ...others,
  }
}

export function useCreateWebSetting(options: MutationOptions) {
  const { loading, handler } = useMutation(createWebSettingQuery, options)
  const [values, setValues] = React.useState<{
    file?: File | null
    name: string
  }>()

  const { uploading, handleUpload } = useUploadImage({
    onCompleted: ({ data }) => {
      handler({ name: values?.name, fileHash: data.hash })
    },
  })

  const handleAdd = (val: { file?: File | null; name: string }) => {
    setValues(val)

    if (val.file) {
      handleUpload({
        variables: { input: { file: val.file } },
      })
      return
    }

    handler({ name: val.name })
  }

  return { creating: loading || uploading, handleAdd }
}

export function useUploadImage(options: MutationOptions = {}) {
  const [handleUpload, { loading: uploading, data, error }] = useApolloMutation(
    uploadImageQuery,
    {
      onError: (error: ApolloError) => {
        if (options.onError) {
          message({
            content: '照片上傳時發生錯誤',
            type: 'error',
          })
        }
      },
      onCompleted: data => {
        if (options.onCompleted) {
          options.onCompleted({
            data: path(['results', 'data'], data) as ImageProp,
          })
        }
      },
    }
  )

  return {
    uploading,
    handleUpload,
  }
}
