import React from 'react'
import styled, { css } from 'styled-components'
import { message as antMessage } from 'antd'
import { Toast } from 'antd-mobile'
import { compose, keys, length } from 'ramda'
import { ConfigOptions } from 'antd/lib/message'
import theme from '@/theme'
import Box from '@/components/common/Box'
import Icon from '@/components/common/Icon'
import { imageUrlPrefix, isMobile } from '@/env'
import { uuid } from '@/utils/webHelper'

type MessageProps = { children?: React.ReactNode; type?: string }

type Props = ConfigOptions & {
  content?: string | React.ReactNode
  type?: 'info' | 'success' | 'error' | 'warning' | 'loading'
  onClose?: () => any
  closable?: boolean
  onClick?: () => any
}

antMessage.config({
  top: 64,
})

export const error = (error: any) => {
  isMobile()
    ? Toast.fail(error.message || (error as any).msg || '伺服器錯誤', 2)
    : baseMessage({
        content: error.message || (error as any).msg || '伺服器錯誤',
        type: 'error',
        maxCount: 1,
        duration: 3,
      })
}

export const message = (
  content: string | React.ReactNode,
  type: 'loading' | 'success' | 'fail' | 'error'
) => {
  isMobile()
    ? Toast[type === 'error' ? 'fail' : type](content, 2)
    : baseMessage({
        content,
        type: type === 'fail' ? 'error' : type,
        maxCount: 1,
        duration: 3,
      })
}

const key = uuid()

export const destroy = antMessage.destroy

const baseMessage = ({
  content,
  type = 'success',
  closable = false,
  onClose,
  duration = 3,
  onClick,
  ...config
}: Props) => {
  if (compose(length, keys)(config) > 0) {
    antMessage.config(config)
  }

  const handleDismiss = () => {
    hide()

    if (onClose) {
      onClose()
    }
  }

  const handleClick = () => {
    if (onClick) {
      onClick()
    }
  }

  const hide = antMessage.open({
    key,
    content: (
      <Message type={type}>
        <Box display="flex" alignItems="center">
          {type === 'success' && (
            <img
              src={`${imageUrlPrefix}/icon-info-active.svg`}
              className="mr-8"
            />
          )}
          {type === 'error' && (
            <img
              src={`${imageUrlPrefix}/icon-alarm-active.svg`}
              className="mr-8"
            />
          )}
          {type === 'warning' && (
            <img
              src={`${imageUrlPrefix}/icon-info-active.svg`}
              className="mr-8"
            />
          )}
          <span onClick={handleClick}>{content}</span>
          {closable && (
            <Box className="btn btn--close">
              <Icon type="close" onClick={handleDismiss} />
            </Box>
          )}
        </Box>
      </Message>
    ) as React.ReactNode,
    duration,
    type: null as any,
  })

  return hide
}

const MessageWrapper = styled.div<MessageProps>`
  position: relative;
  width: 1000px;
  min-height: 60px;
  margin: -10px -16px;
  padding: 18px 10px 10px 40px;
  text-align: left;
  font-size: ${theme.message.fontSize};
  font-weight: ${theme.message.fontWeight};
  color: ${theme.message.color};

  ${p =>
    p.type === 'success' &&
    css`
      background: ${theme.message.bg};
      border: none;
      box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.2);
    `};

  ${p =>
    p.type === 'error' &&
    css`
      background: ${theme.red100};
      border: none;
      box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.2);
    `};

  ${p =>
    p.type === 'warning' &&
    css`
      background: #fff1f0;
      border: none;
      box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.2);
    `};

  .anticon-info-circle > svg > path {
    fill: rgb(182, 127, 40);
  }

  .anticon-close-circle > svg > path {
    fill: #d74c4c;
  }

  .anticon-warning > svg > path {
    fill: #f34c39;
  }

  .btn {
    cursor: pointer;

    &--close {
      position: absolute;
      right: 5px;
      top: 15px;
      font-size: 18px;

      .anticon-close > svg > path {
        fill: #222;
      }
    }
  }
`

const Message = (props: MessageProps) => (
  <MessageWrapper type={props.type}>{props.children}</MessageWrapper>
)

export default baseMessage
