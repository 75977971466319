import React from 'react'
import gql from 'graphql-tag'
import {
  pathOr,
  map,
  forEachObjIndexed,
  split,
  last,
  head,
  equals,
  ifElse,
  __,
  always,
  filter,
  mergeAll,
} from 'ramda'
import { Select, SelectProps } from '@/components/form'
import { useQuery } from '@/hooks/useQuery'
import {
  ApolloQueryVariables,
  Mutation,
  ListQueryResult,
} from '@/constants/types'
import { MutationOptions, useMutation } from '@/hooks/useMutation'
import useFieldDecorator from '@/hooks/useFieldDecorator'
import { getNumberFromString } from '@/utils/webHelper'
const { compose } = require('ramda')

export type Material = {
  id: number
  materialId: number
  materialType: number
  materialTypeName: string
  materialName: string
  materialUnit: string
  materialDescription: string
  materialNameWithoutWatt?: string
  enable: boolean
}

export const materialList = [
  {
    id: 0,
    name: '燈具',
  },
  {
    id: 5,
    name: '電源',
  },
  {
    id: 1,
    name: '控制器',
  },
  {
    id: 2,
    name: '電器件',
  },
  {
    id: 3,
    name: '五金件',
  },
  {
    id: 4,
    name: '線材',
  },
  {
    id: 99,
    name: '其它',
  },
]

export const materialTypes = compose(
  mergeAll,
  map((x: { id: number; name: string }) => ({ [x.id]: x.name }))
)(materialList)

const getMaterialsQuery = gql`
  query getMaterials($params: Input!) {
    results(params: $params)
      @rest(type: "[Material]", path: "/material/materials?{args.params}") {
      data @type(name: "[Material]") {
        materialId
        materialType
        materialName
        materialUnit
        materialDescription
        enable
      }
      total
      totalPages
    }
  }
`

const addMaterialQuery = gql`
  mutation addMaterial($input: Input!) {
    results(input: $input)
      @rest(type: "Material", path: "/material/material", method: "POST") {
      data
    }
  }
`

const updateMaterialQuery = gql`
  mutation updateMaterial($input: Input!) {
    results(input: $input)
      @rest(type: "Material", path: "/material/material", method: "PUT") {
      data
    }
  }
`

// const deleteMaterialQuery = gql`
//   mutation deleteMaterial($input: Input!) {
//     results(input: $input)
//       @rest(type: "Material", path: "/material/material", method: "DELETE") {
//       data
//     }
//   }
// `

const convertData = (data: ListQueryResult<Material> | undefined) => {
  const _data: Material[] = compose(
    map((x: Material) => ({
      ...x,
      id: x.materialId,
      watt: compose(
        getNumberFromString,
        last,
        split('('),
        pathOr('', ['materialName'])
      )(x),
      ...ifElse(
        compose(equals(__, '燈具'), pathOr('', [x.materialType])),
        always({
          materialNameWithoutWatt: compose(
            head,
            split('('),
            pathOr('', ['materialName'])
          )(x),
        }),
        always({})
      )(materialTypes),
      materialTypeName: pathOr('', [x.materialType], materialTypes),
    })),
    pathOr([], ['results', 'data'])
  )(data)

  return _data
}

export function useAllMaterials() {
  const { data, ...others } = useQuery<ListQueryResult<Material>>(
    getMaterialsQuery,
    {
      fetchPolicy: 'cache-first',
    }
  )

  const _data = convertData(data)

  const materialById: { [key: string]: Material } = {}

  _data.forEach((x: Material) => (materialById[x.materialId] = x))

  return {
    data: _data,
    materialById,
    materialEnums: _data,
    ...others,
  }
}

export function useMaterials({
  materialType,
  ...variables
}: ApolloQueryVariables = {}) {
  const { data, ...others } = useQuery<ListQueryResult<Material>>(
    getMaterialsQuery,
    {
      ...variables,
    }
  )

  const _data = compose(
    filter((x: Material) => `${x.materialType}` === materialType),
    convertData
  )(data)

  const materialById: { [key: string]: Material } = {}

  _data.forEach((x: Material) => (materialById[x.materialId] = x))

  return {
    data: _data,
    materialById,
    materialEnums: _data,
    ...others,
  }
}

export function useAddMaterial(options: MutationOptions) {
  const { loading, handler } = useMutation(addMaterialQuery, options)

  return [loading, handler] as Mutation
}

export function useUpdateMaterial(options: MutationOptions) {
  const { loading, handler } = useMutation(updateMaterialQuery, options)

  return [loading, handler] as Mutation
}

export const MaterialSelect: React.ElementType<SelectProps> = React.forwardRef<
  typeof Select,
  SelectProps
>(
  (
    { getFieldDecorator, initialValue, render, ...props }: SelectProps,
    ref
  ): any => {
    const { data } = useAllMaterials()

    let wrapper = useFieldDecorator({
      field: 'materialId',
      getFieldDecorator,
      initialValue,
    })

    const _filter = render ? render : (values: Material[]) => values

    return wrapper(
      <Select forwardRef={ref} allowClear placeholder="選擇材料" {...props}>
        {compose(
          map((x: Material) => (
            <Select.Option key={x.materialId} value={x.materialId}>
              {x.materialName}
            </Select.Option>
          )),
          _filter
        )(data)}
      </Select>
    )
  }
)

export const MaterialTypeSelect = React.forwardRef<typeof Select, SelectProps>(
  (props: SelectProps, ref) => {
    const options: JSX.Element[] = []

    forEachObjIndexed((value: string, key: any) => {
      options.push(
        <Select.Option key={key} value={key}>
          {value}
        </Select.Option>
      )
    }, materialTypes)

    return (
      <Select forwardRef={ref} allowClear placeholder="選擇材料種類" {...props}>
        {options}
      </Select>
    )
  }
)
