import React from 'react'
import styled, { StyledComponent } from 'styled-components'

const ColumnBlock = styled.div`
  padding: 10px 14px 10px 16px;
  font-size: 14px;
  /* font-weight: 500; */
  border-bottom: none;

  :nth-child(odd) {
    color: ${p => p.theme.block.title};
  }

  :nth-child(even) {
    color: ${p => p.theme.block.text};
  }

  &.is--light-grey {
    background: ${p => p.theme.block.content};
  }
`

export default class Column extends React.Component<
  any & { className: string; style: React.CSSProperties },
  any
> {
  public static Dark: StyledComponent<typeof Column, any, {}, never>
  public static Grey: StyledComponent<typeof Column, any, {}, never>
  public static LightGrey: StyledComponent<typeof Column, any, {}, never>
  public static SilverGrey: StyledComponent<typeof Column, any, {}, never>
  public static White: StyledComponent<typeof Column, any, {}, never>
  public static Warning: StyledComponent<typeof Column, any, {}, never>

  public render() {
    return <ColumnBlock {...this.props}>{this.props.children}</ColumnBlock>
  }
}

Column.Dark = styled(Column)`
  display: flex;
  align-items: center;
  width: 120px;
  background: ${p => p.theme.block.section};
  border-right: 1px solid ${p => p.theme.block.borderRight};
  color: #fff;
`

Column.Grey = styled(Column)`
  width: 120px;
  color: ${p => p.theme.block.text};
  background: ${p => p.theme.block.title};
`

Column.LightGrey = styled(Column)`
  display: flex;
  align-items: center;
  width: 160px;
  border-right: 1px solid none;
  background: ${p => p.theme.block.content};
  border-right: 1px solid #e4e4e4;
`

Column.White = styled(Column)`
  display: flex;
  align-items: center;
  width: 160px;
  background: #fff;
  border-right: 1px solid none;
  border-right: 1px solid #e4e4e4;
`

Column.Warning = styled(Column)`
  width: 144px;
  color: #fff;
  background: ${p => p.theme.secondary};
`
Column.SilverGrey = styled(Column)`
  display: flex;
  align-items: center;
  width: 120px;
  border-right: 1px solid none;
  color: #fff !important;
  background: ${p => p.theme.silverGrey};
`
