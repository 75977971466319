import React from 'react'

export const StreetLightConnectionRate = React.lazy(
  () => import('./StreetLightConnectionRateReport')
)

export const ConnectRate = React.lazy(() => import('./ConnectRateReport'))
export const CorrectRate = React.lazy(() => import('./CorrectRateReport'))

export const EnergyReport = React.lazy(() => import('./EnergyReport'))

export const AlarmReport = React.lazy(() => import('./AlarmReport'))
