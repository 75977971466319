import React from 'react'
import styled, { StyledComponentProps } from 'styled-components'
import { Select } from 'antd'
import { SelectValue, SelectProps as AntSelectProps } from 'antd/lib/select'
import { GetFieldDecorator } from './types'

const Option = Select.Option

export type StyledSelectProps<T = SelectValue> = AntSelectProps<T> &
  StyledComponentProps<typeof StyledSelect, any, {}, never> & {
    forwardRef?: React.Ref<any>
    ref?: any
  }

export type SelectProps<T = SelectValue> = StyledSelectProps<T> & {
  getFieldDecorator?: GetFieldDecorator
  initialValue?: any
  render?: (values: any[]) => any
}

const StyledSelect: any = styled(Select)`
  &.ant-select {
    .ant-select-selection:hover,
    .ant-select-selection:active,
    .ant-select-selection:focus {
      border-color: ${p => p.theme.primary};
    }

    .ant-select-selection {
      border-color: ${p => p.theme.gray88};
    }

    .ant-select-selection:active,
    .ant-select-selection:focus {
      box-shadow: none;
    }

    .ant-select-arrow-icon {
      position: relative;
      top: -1px;
      font-size: 14px;
    }

    &.selection__multiple-items--hide
      .ant-select-selection--multiple
      .ant-select-selection__choice__disabled {
      display: none;
    }
  }
`

class SelectComponent<T = any> extends React.Component<
  StyledSelectProps<T>,
  any
> {
  public static Option = Option

  public static displayName = 'Select'

  render() {
    return <StyledSelect {...this.props}>{this.props.children}</StyledSelect>
  }
}

export { SelectComponent as Select }
