import { path, head, pathOr, map, propOr } from 'ramda'
import { uuid } from '@/utils/webHelper'
import { convertData, wrapData } from '@/graphql/utils'
import { useDispatchUnits } from '@/graphql/dispatchUnits'
import { useAllMaterials } from '@/graphql/materials'
import { useVillages } from '@/graphql/sessions'
import { useAreas } from '@/graphql/sessions'
import {
  installationStatus,
  INSTALLED_STATUS,
  UPLOADED,
  uploadStatus,
  useLampInfos,
} from '@/graphql/streetLightEnums'
import { StreetLight, LightLamp, lightMaskEnums } from '@/constants/types'
import { toMoment, toDateString } from '@/utils/webHelper'
const { compose } = require('ramda')

export type ListStreetLightResult<T = StreetLight> = {
  results: {
    data: T[]
    total: number
    totalPages: number
  }
}

export const useConvertStreetLight = () => {
  const { distById } = useVillages()
  const { areaById } = useAreas()
  const { workerById } = useDispatchUnits()
  const { materialById } = useAllMaterials()
  const { oldLampTypeById } = useLampInfos()

  const _convertData = (data?: ListStreetLightResult<StreetLight>) => {
    return convertData<StreetLight>({
      data,
      field: 'data',
      converter: x => {
        const temp = {
          ...x,
          id: x.lightId,
          uuid: uuid(),
          lon: x.lng,
          worker: pathOr('', [x.userId, 'description'], workerById),
          sessionName: pathOr('', [x.sessionNo, 'sessionName'], distById),
          villageName: pathOr('', [x.villageNo, 'villageName'], areaById),
          toGovStatus: +x.toGovStatus,
          installStatus: +x.installStatus,
          isInstalled: x.installStatus === +INSTALLED_STATUS,
          isUploaded: x.toGovStatus === +UPLOADED,
          installStatusName: pathOr('', [x.installStatus], installationStatus),
          toGovStatusName: pathOr('', [x.toGovStatus], uploadStatus),
          installTime: toMoment(x.installTime),
          installTimeStr: toDateString(x.installTime, 'YYYY/MM/DD'),
          pwrApplyDate: toMoment(x.pwrApplyDate),
          pwrFinishDate: toMoment(x.pwrFinishDate),
          pwrApplyDateStr: toDateString(x.pwrApplyDate, 'YYYY/MM/DD'),
          pwrFinishDateStr: toDateString(x.pwrFinishDate, 'YYYY/MM/DD'),
          lightTypeStr: x.lightType,
          poleTypeStr: x.poleType,
          lightMaskStr: propOr('', x.lightMask, lightMaskEnums),
          toGNDStr: x.toGnd === 1 ? '有' : x.toGnd === 0 ? '沒有' : '未量測',
          lamp: compose(
            map((m: LightLamp) => ({
              ...m,
              newCT: pathOr('', ['newCt'], m),
              material: path([m.materialId], materialById),
              materialName: pathOr(
                null,
                [m.materialId, 'materialName'],
                materialById
              ),
              oldLightTypeName: pathOr(
                null,
                [m.oldTypeId, 'typeName'],
                oldLampTypeById
              ),
            })),
            pathOr([], ['lamp'])
          )(x),
          caseAStr: x.caseA === 1 ? '有' : '沒有',
          maintainCoverStr: x.maintainCover === 1 ? '有' : '沒有',
        }
        return {
          ...temp,
          status:
            temp.toGovStatus !== 0
              ? temp.toGovStatusName
              : temp.installStatusName,
        }
      },
    })
  }

  return (data?: ListStreetLightResult<StreetLight>) => _convertData(data)
}

export const convertLight = (converter: any) => {
  return compose(
    head,
    path<any>(['content']),
    converter,
    (data: any) => wrapData({ data }),
    path(['light', 'data'])
  )
}
