import gql from 'graphql-tag'
import moment from 'moment'
import { UploadedFile } from 'shared/components/ImageUpload'

export type Dispatch = {
  dispatchdate?: moment.Moment | undefined
  dispatchDateStr?: string
  dispatchid?: string
  dispatchtype: string
  dispatchunitid: string
  finisheddate?: moment.Moment | undefined
  finishedDateStr?: string
  id: number
  imgurl1?: string | UploadedFile
  imgurl2?: string | UploadedFile
  imgurl3?: string | UploadedFile
  imgurl4?: string | UploadedFile
  imgurl5?: string | UploadedFile
  imgurl6?: string | UploadedFile
  imgurl7?: string | UploadedFile
  iscruise?: boolean
  remark?: string
  repairlog?: string
  repairticketid?: string
  reviewstatus?: string
  scheduleddate?: moment.Moment | undefined
  scheduledDateStr?: string
  state: string | number
  worktype?: string
  lightdata?: string // light json format for maintenance usage

  dispatchUnitName?: string
  dispatchStateName?: string
  dispatchTypeName?: string
  isContractDispatch?: boolean
  isPatrolDispatch?: boolean
}

export const dispatchFragment = gql`
  fragment dispatchInfo on Dispatch {
    dispatchdate
    dispatchid
    dispatchtype
    dispatchunitid
    finisheddate
    id
    imgurl1
    imgurl2
    imgurl3
    imgurl4
    imgurl5
    imgurl6
    imgurl7
    iscruise
    remark
    repairlog
    repairticketid
    reviewstatus
    scheduleddate
    state
    worktype
    lightdata
  }
`

export default dispatchFragment
