import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import { Menu, Layout, Icon } from 'antd'
import { takeLast } from 'ramda'
import styled from 'styled-components'
import { AlarmModes } from '@/graphql/alarm'
import { useProjectInfo, useQueryWebSetting } from '@/graphql/setting'
import history from '@/utils/history'
import useMobile from '@/hooks/useMobile'
import { useAlarmDrawer } from '@/hooks/useAlarmDrawer'
import { Route, RouteItem } from '@/hooks/useFilterRoutes'
import useRoutes from 'src/hooks/useRoutes'

const backgroundColor = '#faf9f9'

const style = { height: '100%', borderRight: 0 }

function SideMenu() {
  const location = useLocation()

  const isMobile = useMobile()

  const routes = useRoutes()

  const { web } = useQueryWebSetting()

  const { info } = useProjectInfo()

  const { handleModeChange, setRectangle } = useAlarmDrawer()

  const [defaultOpenKey, pathname] = location.pathname.split('/').slice(1)

  const [openKeys, setOpenKeys] = React.useState([])

  const handleOpenChange = (openKeys: string[]) => {
    setOpenKeys(takeLast(1, openKeys) as any) // ramda對ts的支援似乎還不夠好...型別會錯
  }

  const handleTitleClick = (evt: React.MouseEvent<HTMLElement>) => {
    handleModeChange(AlarmModes.ALL)()
    setRectangle(null)
    history.push(`${evt.currentTarget.dataset.path}`)
  }

  const handleLogoClick = () => {
    handleModeChange(AlarmModes.ALL)()
    setRectangle(null)
    history.push('/')
  }

  const handleMenuClick = () => {
    handleModeChange(AlarmModes.ALL)()
  }

  const renderIcon = (item: RouteItem) => {
    return item.icon && typeof item.icon === 'string' ? (
      <Icon type={item.icon} />
    ) : typeof item.icon === 'function' ? (
      <item.icon />
    ) : (
      item.icon
    )
  }

  const renderMenuItem = (item: RouteItem) => {
    if (!item.readable) {
      return
    }

    const to = `${item.path}`

    return (
      <MenuItem key={item.id} disabled={item.disabled}>
        <Link to={to} onClick={handleMenuClick}>
          {item.title}
        </Link>
      </MenuItem>
    )
  }

  const renderMenu = (data: any[]) =>
    data.map((item: Route) => {
      if (!item.readable) {
        return []
      }

      if (!item.isSubMenu) {
        const to = `${item.path}`

        return (
          <MainMenu key={item.path} disabled={item.disabled}>
            <Link data-path={item.path} to={to} onClick={handleTitleClick}>
              {renderIcon(item)}
              {item.title}
            </Link>
          </MainMenu>
        )
      }

      return item.children ? (
        <SubMenu
          key={item.id}
          title={
            <span>
              {renderIcon(item)}
              <span>{item.title}</span>
            </span>
          }
          disabled={item.disabled}>
          {item.children.map(renderMenuItem)}
        </SubMenu>
      ) : (
        <li
          key={item.id}
          data-path={item.path}
          onClick={item.disabled ? () => {} : handleTitleClick}
          className={
            item.disabled
              ? 'ant-menu-submenu ant-menu-submenu-inline ant-menu-submenu-selected ant-menu-submenu-disabled'
              : 'ant-menu-submenu ant-menu-submenu-inline ant-menu-submenu-selected'
          }>
          <div className="ant-menu-submenu-title l-flex is--middle">
            {renderIcon(item)}
            <span css={{ display: 'inline-flex' }}>{item.title}</span>
          </div>
        </li>
      )
    })

  return (
    <Sider collapsed={isMobile} width={160}>
      <Logo onClick={handleLogoClick} className="cursor--pointer">
        <img src={web.url} alt={web.name} />
        <div className="logo-text">{info.projectName}</div>
      </Logo>
      <StyledMenu
        mode="inline"
        openKeys={openKeys}
        defaultSelectedKeys={[pathname || defaultOpenKey]}
        defaultOpenKeys={[defaultOpenKey]}
        onOpenChange={handleOpenChange}
        style={style}>
        {renderMenu(routes)}
      </StyledMenu>
    </Sider>
  )
}

const Sider = styled(Layout.Sider)`
  &.ant-layout-sider {
    background: ${backgroundColor};
    /* box-shadow: 2px 0 6px 0 rgba(0, 0, 0, 0.5); */
  }

  .ant-layout-sider-children {
    display: flex;
    flex-direction: column;
    height: calc(100vh - 60px);
  }
`

const StyledMenu = styled(Menu)`
  &.ant-menu {
    height: auto !important;
    margin-top: 24px;
    margin-bottom: auto;
    background: ${backgroundColor};
  }

  &.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background: ${backgroundColor};

    > a {
      color: ${p => p.theme.primary};
    }

    &:after {
      display: none;
    }
  }

  .ant-menu-submenu-title {
    color: ${p => p.theme.grey};

    i[class*='icon-'] {
      > svg > g > g {
        transition: stroke 0.3s ease;
        stroke: ${p => p.theme.grey};
      }
    }
  }

  .ant-menu-submenu-title:hover {
    color: ${p => p.theme.primary};
    background: ${p => p.theme.menu.hoverBg};

    i[class*='icon-'] {
      > svg > path {
        fill: ${p => p.theme.primary};
      }
    }
  }

  &.ant-menu-inline {
    .ant-menu-item {
      width: auto;
      margin-top: 0;
      margin-bottom: 0;
      color: #fff;
      font-weight: 500;
      font-size: 13px;

      &:hover {
        color: #fff;
      }
    }

    .ant-menu-item:active,
    .ant-menu-submenu-title:active {
      background: ${p => p.theme.menu.activeBg};
    }

    .ant-menu-item:not(:last-child) {
      margin-bottom: 0;
    }

    .ant-menu-submenu > .ant-menu-submenu-title {
      height: auto;
    }

    .ant-menu-submenu-inline > .ant-menu-submenu-title .ant-menu-submenu-arrow {
      right: 10px;
      top: 47%;
    }

    .ant-menu-inline
      .ant-menu-submenu-inline
      > .ant-menu-submenu-title
      .ant-menu-submenu-arrow::after {
      height: 1px;
      background: ${p => p.theme.grey};
    }

    .ant-menu-submenu-open.ant-menu-submenu-inline
      > .ant-menu-submenu-title
      .ant-menu-submenu-arrow {
      top: 43%;
    }

    .ant-menu-submenu-inline
      > .ant-menu-submenu-title
      .ant-menu-submenu-arrow::after {
      transform: rotate(45deg) translateX(-3px);
      width: 7px;
      height: 1px;
    }

    .ant-menu-submenu-inline
      > .ant-menu-submenu-title
      .ant-menu-submenu-arrow::before {
      transform: rotate(-45deg) translateX(3px);
      width: 7px;
      height: 1px;
    }

    .ant-menu-submenu-open.ant-menu-submenu-inline
      > .ant-menu-submenu-title
      .ant-menu-submenu-arrow::before {
      transform: rotate(45deg) translateX(3px);
    }

    .ant-menu-submenu-open.ant-menu-submenu-inline
      > .ant-menu-submenu-title
      .ant-menu-submenu-arrow::after {
      transform: rotate(-45deg) translateX(-3px);
    }

    .ant-menu-submenu-inline
      > .ant-menu-submenu-title
      .ant-menu-submenu-arrow::after,
    .ant-menu-submenu-inline
      > .ant-menu-submenu-title
      .ant-menu-submenu-arrow::before {
      background: ${p => p.theme.grey};
    }

    .ant-menu-submenu-inline
      > .ant-menu-submenu-title:hover
      .ant-menu-submenu-arrow::before,
    .ant-menu-submenu-inline
      > .ant-menu-submenu-title:hover
      .ant-menu-submenu-arrow::after {
      background: ${p => p.theme.primary};
    }

    .ant-menu-submenu-title {
      margin-top: 6px;
      padding-left: 10px !important;
      padding-bottom: 6px;
      /* border-bottom: 1px solid #484848; */
      font-size: 14px;

      .anticon {
        position: relative;
        top: 1px;
        margin-right: 6px;
      }

      > span {
        display: flex;
        align-items: center;
      }
    }

    .ant-menu-submenu-selected {
      color: ${p => p.theme.primary};
      background: ${p => p.theme.menu.activeBg};

      > .ant-menu-submenu-title {
        color: ${p => p.theme.primary};
      }

      i[class*='icon-'] {
        > svg > path {
          fill: ${p => p.theme.primary};
        }
      }
    }
  }
`

const SubMenu = styled(Menu.SubMenu)`
  color: #fff;

  &.ant-menu-submenu > .ant-menu:not(:empty) {
    padding-bottom: 20px;
    background-color: ${backgroundColor};
  }

  .ant-menu-sub.ant-menu-inline > .ant-menu-item {
    height: 36px;
    line-height: 36px;
  }
`

const MainMenu = styled(Menu.Item)`
  &.ant-menu-item {
    height: 48px;
    line-height: 48px;
    padding-left: 10px !important;
    background-color: ${backgroundColor};

    > a {
      font-size: 14px;
      font-weight: normal;
      color: ${p => p.theme.grey};

      .anticon {
        vertical-align: middle;
        margin-right: 6px;
      }
    }
  }

  &.ant-menu-item:hover,
  &.ant-menu-item-selected {
    background: ${p => p.theme.menu.hoverBg} !important;

    > a {
      color: ${p => p.theme.primary};
    }

    i[class*='icon-'] {
      > svg > path {
        fill: ${p => p.theme.primary};
      }
    }
  }
`

const MenuItem = styled(Menu.Item)`
  &.ant-menu-item {
    padding-left: 37px !important;
    /* border-bottom: 1px solid #484848; */
    text- &:active {
      background: ${backgroundColor};
    }

    > a {
      color: ${p => p.theme.grey};
      font-weight: normal;

      &:hover {
        color: ${p => p.theme.primary};
      }
    }
  }
`

const Logo = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: 15px;
  margin-bottom: 25px;
  text-align: center;
  font-weight: 500;

  .logo-text {
    color: #201817;
    font-size: 12px;
  }

  img {
    width: 100%;
    height: auto;
    max-width: 127px;
  }
`

export default SideMenu
