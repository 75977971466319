import React from 'react'
import { InputNumber } from 'antd'
import { InputNumberProps } from 'antd/lib/input-number'
import styled from 'styled-components'
import { RefProps } from './types'
import { percent, currency, thosandSeprartor } from '../../utils/form'

const StyledInputNumber = styled(InputNumber).attrs({
  min: 0 as any, // dont know why error
})`
  &.ant-input-number-focused,
  &.ant-input-number:focus,
  &.ant-input-number:hover {
    border-color: ${p => p.theme.primary};
  }

  &.ant-input-number-focused,
  &.ant-input-number:focus {
    box-shadow: 0 0 0 2px ${p => p.theme.primary};
  }
`

export const ThousandSeprartorInput = React.forwardRef<
  typeof InputNumber,
  RefProps & InputNumberProps & { css?: React.CSSProperties }
>(({ ...props }, ref) => (
  <StyledInputNumber forwardRef={ref} {...props} {...thosandSeprartor} />
))

export const CurrencyInput = React.forwardRef<
  typeof InputNumber,
  RefProps & InputNumberProps & { css?: React.CSSProperties }
>(({ ...props }, ref) => (
  <StyledInputNumber forwardRef={ref} {...props} {...currency} />
))


export const PercentInput = React.forwardRef<
  typeof InputNumber,
  RefProps & InputNumberProps & { css?: React.CSSProperties }
>(({ ...props }, ref) => (
  <StyledInputNumber forwardRef={ref} {...props} {...percent} />
))

ThousandSeprartorInput.displayName = 'ThousandSeprartorInput'

CurrencyInput.displayName = 'CurrencyInput'

PercentInput.displayName = 'PercentInput'

export { StyledInputNumber as InputNumber }
