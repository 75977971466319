class EnvStore {
  pageSize = 10
  apiBaseUrl =
    // https://cors-anywhere.herokuapp.com/http://lightinstall.f2fcloud.com:8008/api/v1
    process.env.REACT_APP_API_ROOT ||
    // 'http://lightinstall.f2fcloud.com/api/v1'
    'http://59.120.53.132:8008/api/v1'

  get isEnvDev(): boolean {
    return process.env.NODE_ENV === 'development'
  }
  get appBaseName(): string {
    return process.env.REACT_APP_BASE_NAME || ''
  }
  get adminApiBaseUrl(): string {
    return (
      process.env.REACT_APP_ADMIN_API_ROOT ||
      'http://59.120.53.132/TainanInstall/api/admin/v1'
    )
  }
  get fakeApiBaseUrl(): string {
    return (
      process.env.REACT_APP_FAKE_API_ROOT ||
      (this.isEnvDev ? 'http://localhost:3004' : 'http://59.120.53.132:3004')
    )
  }
  get googleMapsApiKey(): string {
    return (
      process.env.REACT_APP_GOOGLE_MAPS_KEY ||
      (this.isEnvDev
        ? 'AIzaSyD-ZETNFwBmkF7xcFu45OMUqttyVgNs2gc'
        : 'AIzaSyD-ZETNFwBmkF7xcFu45OMUqttyVgNs2gc')
    )
  }
  get googleReCAPTCHAKey(): string {
    return (
      process.env.REACT_APP_GOOGLE_RECAPTCHA_KEY ||
      '6LfVncQUAAAAACx7g4lBiP4_k_N0J7ZApSYcbnd8'
    )
  }
  get googleMapsGeocodingApiBaseUrl(): string {
    return 'https://maps.googleapis.com/maps/api/geocode'
  }
  get socketUrl(): string {
    return (
      process.env.REACT_APP_FAKE_API_ROOT ||
      (this.isEnvDev ? 'http://localhost:4015' : 'http://59.120.53.132:4015')
    )
  }
  get publicUrl(): string {
    return process.env.PUBLIC_URL || ''
  }
  get appUrl(): string {
    return `${window.location.protocol}//${window.location.host}${this.appBaseName}`
  }
  get canUseDOM(): boolean {
    return !!(
      typeof window !== 'undefined' &&
      window.document &&
      window.document.createElement
    )
  }
  get imageUrlPrefix(): string {
    return `${this.publicUrl}/images`
  }

  set setPageSize(pageSize: number | undefined) {
    this.pageSize = pageSize || 50
  }

  set setApiBaseUrl(url: string) {
    this.apiBaseUrl = url
  }
}

export const envStore = new EnvStore()

export const isEnvDev: boolean = envStore.isEnvDev
export const apiBaseUrl: string = envStore.apiBaseUrl
export const appBaseName = envStore.appBaseName
export const appUrl: string = envStore.appUrl
export const canUseDOM: boolean = envStore.canUseDOM
export const publicUrl: string | undefined = envStore.publicUrl
export const googleMapsApiKey = envStore.googleMapsApiKey
export const imageUrlPrefix = envStore.imageUrlPrefix
export const googleReCAPTCHAKey = envStore.googleReCAPTCHAKey
export const googleMapsGeocodingApiBaseUrl =
  envStore.googleMapsGeocodingApiBaseUrl
export const fakeApiBaseUrl = envStore.fakeApiBaseUrl
export const adminApiBaseUrl = envStore.adminApiBaseUrl
export const socketUrl = envStore.socketUrl

export const endpoints = {
  apiBaseUrl,
  fakeApiBaseUrl,
  adminApiBaseUrl,
}

export function isWebView() {
  const userAgent = navigator.userAgent
  const rules = [
    'WebView',
    '(iPhone|iPod|iPad)(?!.*Safari/)',
    'Android.*(wv|.0.0.0)',
  ]

  const regex = new RegExp(`(${rules.join('|')})`, 'ig')
  return Boolean(userAgent.match(regex))
}

export function isMobile() {
  let check = false
  ;(function (a) {
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(
        a
      ) ||
      // eslint-disable-next-line no-useless-escape
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
        a.substr(0, 4)
      )
    )
      check = true
  })(navigator.userAgent || navigator.vendor || (window as any).opera)
  return check
}

export const defaultPageSize = () => envStore.pageSize
