import '@/bootstrap'
import React from 'react'
import ReactDOM from 'react-dom'
import renderer from '@/utils/renderer'
import { createPack } from '@/utils/createPack'
import { GoogleMapProvider } from '@/hooks/useGoogleMap'
// import { publicUrl } from '@/env'
import App from 'src/containers/App'
import * as serviceWorker from './serviceWorker'

// If the pathname is `/` then redirect to `/SCTaiChung/lightMaintain`

if (process.env.NODE_ENV === 'development') {
  // const { server } = require('../mock-server')
  // server.start({
  //   serviceWorker: {
  //     url: `${publicUrl}/mockServiceWorker.js`,
  //   },
  // })
}

const app = renderer()(App)

const EnhancedApp = createPack(GoogleMapProvider)

ReactDOM.render(
  <EnhancedApp>{app}</EnhancedApp>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
