import React from 'react'
import gql from 'graphql-tag'
import { useMutation as useApolloMutation } from '@apollo/react-hooks'
import moment from 'moment'
import {
  map,
  equals,
  update,
  head,
  find,
  forEach,
  pathOr,
  compose,
  path,
  is,
} from 'ramda'
import { pathBuilder, wrapData, convertData } from './utils'
import { Select, SelectProps } from 'shared/components/form'
import {
  AntVirtualSelect,
  AntVirtualSelectProps,
} from 'shared/components/AntVirtualSelect'
import { useQuery, useLazyQuery } from 'shared/hooks/useQuery'
import useFieldDecorator from 'shared/hooks/useFieldDecorator'
import usePrevious from 'shared/hooks/usePrevious'
import { useMutation, MutationOptions } from 'shared/hooks/useMutation'
import { ApolloQueryVariables, PickerProps } from 'shared/constants/types'
import { uuid, toMoment, momentToString } from 'shared/utils/webHelper'
import Picker from '@/components/common/Picker'
import PickerInput from '@/components/common/PickerInput'

export type Contract = {
  budgetBalance?: number
  budgetExtend?: number
  caseName?: string
  contractId: number | string
  id: number | string
  code?: string
  includeCruise?: number | boolean
  dispatchdate?: string
  finisheddate?: string
  quality: number | string
  safety: number | string
  shlifi: number | string
  price: number | string
  startTime: moment.Moment | undefined
  stopTime: moment.Moment | undefined
  supervisor: string
  supervisorName: string
  supplier: string
  supplierName: string
  totalBudget: number | string
  valueTax: number | string
  status?: string
  contractDetails: ImportContract[]

  budgetbalance: number
  budgetextend: number
  casename: string
  contractid: string
  includecruise: boolean
  starttime: string
  stoptime: string
  totalbudget: number
  valuetax: number

  starttimeStr: string
  stoptimeStr: string
  suppliername: string
  supervisorname: string
}

export type ImportContract = {
  contractid: string
  id?: number | string
  isenable: string
  item: string
  note: string
  price: number
  quantity: number
  totalprice: number
  unit: string
}

export type ListContractsResult = {
  results: {
    contractList: Contract[]
    total: number
  }
}

export type ContractDetail = {
  tbcontract: Contract
  tbcontractdetails: ImportContract[]
}

export type GetContractResult = {
  results: {
    contractDetail: ContractDetail
  }
}

export type Vendor = {
  address: string
  availabledist: string
  code: string
  contactname: string
  createddate: string
  createduser: number
  email: string
  id: number
  memo: string
  moblie: string
  modifydate: string
  modifyuser: number
  phone: string
  unitname: string
  unittype: string
}

export const vendorFragment = gql`
  fragment vendorInfo on Vendor {
    address
    availabledist
    code
    contactname
    createddate
    createduser
    email
    id
    memo
    moblie
    modifydate
    modifyuser
    phone
    unitname
    unittype
  }
`

export const getContractPathBuilder = pathBuilder('/maintain/getContracts')

const contractFragmentString = `
  budgetBalance
  budgetbalance
  budgetExtend
  budgetextend
  caseName
  casename
  contractId
  contractid
  id
  includeCruise
  includecruise
  quality
  safety
  shlifi
  startTime
  starttime
  stopTime
  stoptime
  supervisor
  supervisorName
  supplier
  supplierName
  totalBudget
  totalbudget
  valueTax
  valuetax
`

export const contractFragment = gql`
  fragment contractInfo on Contract {
    ${contractFragmentString}
  }
`

export const getContractsQuery = gql`
  query getContracts($params: Input!) {
    results(params: $params)
      @rest(type: "Contract", pathBuilder: $pathBuilder) {
      contractList @type(name: "Contract") {
        ...contractInfo
      }
      total
    }
  }
  ${contractFragment}
`

export const getContractQuery = gql`
  query getContract($id: Number!) {
    results(id: $id)
      @rest(type: "ContractDetail", path: "/maintain/Contract/{args.id}") {
      contractDetail @type(name: "ContractDetail") {
        tbcontract @type(name: "Contract") {
          ...contractInfo
        }
        tbcontractdetails @type(name: "ImportContract") {
          contractid
          id
          isenable
          item
          note
          price
          quantity
          totalprice
          unit
        }
      }
      total
    }
  }
  ${contractFragment}
`

export const createContractQuery = gql`
  mutation addContract($input: Contract) {
    results(input: $input)
      @rest(type: "Contract", method: "POST", path: "/maintain/newContract") {
      id
    }
  }
`

export const updateContractQuery = gql`
  mutation updateContract($input: Contract) {
    results(input: $input)
      @rest(type: "Contract", method: "PUT", path: "/maintain/updateContract") {
      id
    }
  }
`

export const importContractQuery = gql`
  mutation importContract($input: Input!) {
    results(input: $input)
      @rest(
        type: "Contract"
        path: "/maintain/importContractJson"
        method: "POST"
        bodySerializer: "form"
      ) {
      contractid
      item
      id
      price
      quantity
      totalprice
      unit
      isenable
      note
    }
  }
`

export const getVendorsQuery = gql`
  query getVendors($input: Input!) {
    vendors @rest(type: "Vendor", path: "/basic/contact/DT02") {
      ...vendorInfo
    }
  }
  ${vendorFragment}
`

export const getSupervisorsQuery = gql`
  query getSupervisors($input: Input!) {
    supervisors @rest(type: "Vendor", path: "/basic/contact/DT05") {
      ...vendorInfo
    }
  }
  ${vendorFragment}
`

const useConvertData = (data?: ListContractsResult) => {
  const { vendorById, vendorEnums } = useVendors()
  const { supervisorById } = useSupervisors()

  const dataSource = convertData<Contract>({
    data,
    field: 'contractList',
    converter: x => ({
      ...x,
      caseName: x.caseName || x.casename,
      budgetBalance: x.budgetBalance || x.budgetbalance,
      budgetExtend: x.budgetExtend || x.budgetextend,
      totalBudget: x.totalBudget || x.totalbudget,
      contractId: x.contractId || x.contractid,
      valueTax: x.valueTax || x.valuetax,
      startTime: toMoment(x.startTime || x.starttime),
      stopTime: toMoment(x.stopTime || x.stoptime),
      startTimeStr: momentToString(x.startTime || x.starttime),
      stopTimeStr: momentToString(x.stopTime || x.stoptime),
      safety: Number(x.safety),
      shlifi: Number(x.shlifi),
      quality: Number(x.quality),
      includeCruise: x.includeCruise || x.includecruise ? 1 : 0,
      supplier: is(String, x.supplier)
        ? compose(
            pathOr(x.supplier, ['id']),
            find(a => a.unitname === x.supplier)
          )(vendorEnums)
        : x.supplier,
      ...(is(Number, x.supervisor) || +x.supervisor
        ? {
            supervisorName: pathOr(
              x.supervisor,
              [x.supervisor, 'contactname'],
              supervisorById
            ),
          }
        : { supervisorName: x.supervisor }),
      ...(is(Number, x.supplier) || +x.supplier
        ? {
            supplierName: pathOr(
              x.supplier,
              [x.supplier, 'unitname'],
              vendorById
            ),
          }
        : { supplierName: x.supplier }),
    }),
  })

  return dataSource
}

export function useContracts(variables?: ApolloQueryVariables) {
  const { data, ...others } = useQuery<ListContractsResult>(getContractsQuery, {
    ...variables,
    pathBuilder: getContractPathBuilder,
  })

  const dataSource = useConvertData(data)

  return { ...others, dataSource }
}

export function useAllContracts(variables?: ApolloQueryVariables) {
  const { data, ...others } = useQuery<ListContractsResult>(getContractsQuery, {
    ...variables,
    pageSize: 100000,
    pathBuilder: getContractPathBuilder,
    fetchPolicy: 'cache-first',
  })

  const dataSource = useConvertData(data)

  let contractBelongVendor: { [key: string]: Contract } = {}
  let contractById: { [key: string]: Contract } = {}

  forEach(x => {
    contractBelongVendor[x.supplier] = x
    contractById[x.contractId] = x
  }, dataSource.content)

  return { ...others, dataSource, contractBelongVendor, contractById }
}

export function useQueryContract() {
  const [
    queryContract,
    {
      loading,
      data: { results: { contractDetail = {} as ContractDetail } = {} } = {},
      error,
    },
  ] = useLazyQuery<GetContractResult>(getContractQuery)

  const tbcontract = compose(
    head,
    path<any>(['content'])
  )(
    useConvertData(
      wrapData({ contractList: path(['tbcontract'], contractDetail) })
    )
  )

  return {
    queryContract,
    contractDetail: {
      ...contractDetail,
      tbcontract,
    },
    loading,
    error,
  }
}

export function useCreateContract(options: MutationOptions) {
  const { loading: creating, handler: handleAdd } = useMutation(
    createContractQuery,
    options
  )

  return { creating, handleAdd }
}

export function useUpdateContract(options: MutationOptions) {
  const { loading: updating, handler: handleUpdate } = useMutation(
    updateContractQuery,
    options
  )

  return { updating, handleUpdate }
}

export function useImportContract(
  initialValue: ImportContract[] = [],
  options: MutationOptions = {}
) {
  const [contracts, setContracts] = React.useState<ImportContract[]>([])

  const prevInitValue = usePrevious(initialValue)

  React.useEffect(() => {
    if (!equals(prevInitValue, initialValue)) {
      setContracts([...initialValue, ...contracts])
    }
  }, [prevInitValue, initialValue])

  const [
    handleImport,
    { loading, data: { results = [] } = {} },
  ] = useApolloMutation(importContractQuery, options)

  const prevResults = usePrevious(results)

  React.useEffect(() => {
    if (!equals(prevResults, results) && is(Array, results)) {
      setContracts([
        ...contracts,
        ...map(
          (x: ImportContract) => ({
            ...x,
            id: uuid(),
            note: x.note || '未填寫備註',
            unit: x.unit || '未設定',
            item: x.item || '未設定',
          }),
          results
        ),
      ])
    }
  }, [prevResults, results, contracts])

  const handleChange = (index: number) => (value: any) => {
    const target = contracts[index]

    setContracts(
      update(index, {
        ...target,
        isenable: target.isenable === 'Y' ? 'N' : 'Y',
      })
    )
  }

  return {
    loading,
    contracts,
    handleImport,
    handleChange,
  }
}

export const ContractSelect: React.ElementType<AntVirtualSelectProps> = React.forwardRef<
  typeof AntVirtualSelect,
  AntVirtualSelectProps
>(
  (
    { getFieldDecorator, initialValue, ...props }: AntVirtualSelectProps,
    ref
  ): any => {
    const { dataSource } = useContracts({
      pageSize: 100000,
    })

    let wrapper = useFieldDecorator({
      field: 'contractid',
      getFieldDecorator,
      initialValue,
    })

    return wrapper(
      <AntVirtualSelect
        forwardRef={ref}
        allowClear
        placeholder="請選擇合約"
        {...props}>
        {dataSource.content.map(x => (
          <Select.Option key={x.contractId} value={x.contractId}>
            {x.contractId}
          </Select.Option>
        ))}
      </AntVirtualSelect>
    )
  }
)

export const ContractMobileSelect: React.ElementType<PickerProps> = React.forwardRef<
  typeof Picker,
  PickerProps
>(
  (
    { getFieldDecorator, initialValue, className, ...others }: PickerProps,
    ref
  ): any => {
    const { dataSource } = useContracts({
      pageSize: 100000,
    })

    let wrapper = useFieldDecorator({
      field: 'reportWays',
      getFieldDecorator,
      initialValue,
    })

    const props = {
      forwardRef: ref,
      data: map(
        x => ({ label: x.contractId, value: x.contractId }),
        dataSource.content
      ),
      ...others,
    }

    return wrapper(
      <Picker {...props} cols={1}>
        <PickerInput placeholder="選擇合約" className={className} />
      </Picker>
    )
  }
)

/**
 * 廠商
 */
export const useVendors = () => {
  const {
    data: { vendors = [] } = {},
    loading,
    error,
  } = useQuery(getVendorsQuery, { fetchPolicy: 'cache-first' })

  let vendorById: { [key: number]: Vendor } = {}

  forEach((x: Vendor) => (vendorById[x.id] = x), vendors)

  return {
    vendorEnums: vendors as Vendor[],
    vendorById,
    loading,
    error,
  }
}

/**
 * 監造
 */
export const useSupervisors = () => {
  const {
    data: { supervisors = [] } = {},
    loading,
    error,
  } = useQuery(getSupervisorsQuery, { fetchPolicy: 'cache-first' })

  let supervisorById: { [key: string]: Vendor } = {}

  forEach((x: Vendor) => (supervisorById[x.id] = x), supervisors)

  return {
    supervisorEnums: supervisors as Vendor[],
    supervisorById,
    loading,
    error,
  }
}

export const VendorSelect = React.forwardRef<typeof Select, SelectProps>(
  ({ getFieldDecorator, initialValue, ...props }: SelectProps, ref): any => {
    const { vendorEnums } = useVendors()

    let wrapper = useFieldDecorator({
      field: 'supplier',
      getFieldDecorator,
      initialValue,
    })

    return wrapper(
      <Select forwardRef={ref} allowClear placeholder="請選擇廠商" {...props}>
        {vendorEnums.map((x: Vendor) => (
          <Select.Option key={x.id} value={x.id}>
            {x.unitname}
          </Select.Option>
        ))}
      </Select>
    )
  }
)

export const SupervisorSelect = React.forwardRef<typeof Select, SelectProps>(
  ({ getFieldDecorator, initialValue, ...props }: SelectProps, ref): any => {
    const { supervisorEnums } = useSupervisors()

    let wrapper = useFieldDecorator({
      field: 'supervisor',
      getFieldDecorator,
      initialValue,
    })

    return wrapper(
      <Select forwardRef={ref} allowClear placeholder="請選擇監造" {...props}>
        {supervisorEnums.map((x: Vendor) => (
          <Select.Option key={x.id} value={x.id}>
            {x.unitname}
          </Select.Option>
        ))}
      </Select>
    )
  }
)

VendorSelect.displayName = 'VendorSelect'
