import React from 'react'
import gql from 'graphql-tag'
import moment from 'moment'
import { pathOr, isEmpty, __, map, path } from 'ramda'
import { toMoment, momentToString, dateToYYYYMMDDHHMM } from '@/utils/webHelper'
import { convertData } from '@/graphql/utils'
import useReportStates from '@/graphql/reportStates'
import { convertLight, useConvertStreetLight } from '@/graphql/apiDataMapper'
import { streetLightFramgment } from '@/graphql/fragments/streetLightFragment'
import { useRepairEnum } from '@/graphql/repair'
import { Dispatch } from '@/graphql/fragments/dispatchFragment'
import { useQuery, useLazyQuery } from '@/hooks/useQuery'
import { useMutation, MutationOptions } from '@/hooks/useMutation'
import {
  ApolloQueryVariables,
  OrderDetail,
  DataSource,
  Mutation,
  StreetLight,
} from '@/constants/types'
import { UploadedFile } from '@/components/ImageUpload'

export type RepairHistory = {
  createAt: moment.Moment
  createAtStr: string
  creator: string
  id: number
  info: string
  reason: string
  remark: string
  type: string
  typeStr: string
}

export type RepairDist = string

export type RepairDistEnums = Array<RepairDist>

type Worker = {
  id: number
  memberId: string
  nickname: string
}

type ClosedAttachment = {
  fileHash: string
  filePath: string
  url: string
}

export type RepairLight = {
  address: string
  deviceName: string
  id: number
  lastRepairAt: moment.Moment
  lastRepairAtStr: string
  sessionName: string
  info: StreetLight
}

export type Repair = {
  address: string
  brokenReasonValue: string
  brokenReasonStr: string
  code: string
  createAt: moment.Moment
  createAtStr: string
  deviceName: string
  finishType: string
  id: number
  lat: number
  lightNo: string | null
  lightSeq: number
  lon: number
  remark: string
  sessionName: string
  state: number | string
  stateStr: string
  worktype: string //未使用
  //repair detail
  closedAttachments: ClosedAttachment[]
  closedRemark: string
  histories: RepairHistory[]
  lastRepairAt: moment.Moment
  lastRepairAtStr: string
  closedAt: moment.Moment
  closedAtStr: string

  ///----TODO 要刪除的---//
  createDate: moment.Moment
  finisheddate: moment.Moment
  dispatchUnit: string
  distid: number
  dmgdesc: string

  isdelete: boolean
  isgisready: boolean
  isnotice: boolean

  mediaurl: string
  origofficeid: number

  reportWay: string
  reportdate: moment.Moment
  reportDate: moment.Moment
  reporterdesc: string
  reportermail: string
  reportername: string
  reporterphone: string
  statename?: string
  dispatchnumber: number
  poleCode: string
  polecode: string

  brokenReason: string
  cState: string
  streetLightId: number
  workType: string
  distName: string
  location: string
  createdate: moment.Moment
  reportways: string

  createDateStr: string
  reportdateStr: string
  reportWayName: string
  brokenreason?: string
  distname: string
  isWaitForDispatch: boolean
  isSmartLight: boolean
  /**
   * 異動
   */
  isTransfer: boolean
  /**
   * 遷移
   */
  isChange: boolean
  /**
   * 新增
   */
  isCreate: boolean
  /**
   * 刪除
   */
  isDelete: boolean
  /**
   * 報修
   */
  isRepair: boolean
  /**
   * 是否結案
   */
  isClosed: boolean
  /**
   * 合約派工
   */
  isContract: boolean
  isNoticeName: string
  workTypeName: string
  fullAddress: string
  /**
   * 派工維修狀態
   */
  dispatch: Partial<Dispatch>

  /**
   * 編輯用料登記的欄位
   */
  orderDetail: OrderDetail[]

  /**
   * 退回派工資訊
   */
  reject: RejectRepair

  light: StreetLight
}

export type RejectRepair = {
  log: string
  repairTicketId: string
  dispatchId?: string
  imgurl1?: string | UploadedFile
  imgurl2?: string | UploadedFile
  imgurl3?: string | UploadedFile
}

export type ClosedEditRepair = {
  address: string
  deviceName: string
  hashs: string[]
  lightNo: string
  remark: string
  sessionName: string
}

export type ListRepaisResult = {
  results: {
    repair: Repair[]
    total: number
  }
}

export type RepairLightResult = {
  repairlight: RepairLight
}

export type GetRepairResult = {
  repair: Repair
}

// HACK: can't write fragment in gql because facing the heuristic-fragment-matcher bug.
const listRepairsFragment = `
  address
  brokenReasonValue
  code
  closedAt
  createAt
  deviceName
  finishType
  id
  lat
  lightNo
  lightSeq
  lon
  remark
  sessionName
  state
  worktype
  worker @type(name: "Worker") {
    id
    memberId
    nickname
  }
`

const repairFragment = gql`
  fragment repairInfo on Repair {
    ${listRepairsFragment}
  }
`

export const getRepairsQuery = gql`
  query getRepairs($params: Input!) {
    results(params: $params)
      @rest(
        type: "[Repair]"
        path: "/repairTicket/{args.params.current}/{args.params.pageSize}?{args.params}"
      ) {
      data @type(name: "Repair") {
        ...repairInfo
      }
      total
      totalPages
    }
  }
  ${repairFragment}
`

export const getRepairQuery = gql`
  query getRepair($id: String!) {
    repair(id: $id) @rest(type: "Repair", path: "/repairTicket/{args.id}") {
      data {
        ${listRepairsFragment}
        lightSeq @export(as: "lightSeq")
        light
          @rest(
            type: "StreetLight"
            path: "/device/light/{exportVariables.lightSeq}"
          ) {
          data @type(name: "StreetLight") {
            ...streetLightInfo
          }
        }
        histories
        closedRemark
        closedAttachments
      }
      errorMsg
    }
  }
  ${streetLightFramgment}
`

export const createRepairQuery = gql`
  mutation addRepair($input: Repair) {
    results(input: $input)
      @rest(type: "Repair", method: "POST", path: "/repairTicket") {
      data
      errorMsg
    }
  }
`

export const updateRepairQuery = gql`
  mutation updateRepair($input: Repair) {
    results(input: $input)
      @rest(
        type: "Repair"
        method: "PUT"
        path: "/repairTicket/{args.input.id}"
      ) {
      data
      errorMsg
    }
  }
`

export const closeRepairQuery = gql`
  mutation closeRepair($input: Repair) {
    results(input: $input)
      @rest(type: "Repair", method: "POST", path: "/repairTicket/batchClosed") {
      data
      errorMsg
    }
  }
`

export const rejectRepairQuery = gql`
  mutation rejectRepair($input: Repair) {
    results(input: $input)
      @rest(
        type: "Repair"
        method: "POST"
        path: "/repairTicket/{args.input.id}/reject"
      ) {
      data
      errorMsg
    }
  }
`

export const closedEditRepairQuery = gql`
  mutation closedEditRepair($input: Repair) {
    results(input: $input)
      @rest(
        type: "ClosedEditRepair"
        method: "PUT"
        path: "/repairTicket/{args.input.id}/closedEdit"
      ) {
      data
      errorMsg
    }
  }
`

export const exportRepairQuery = gql`
  query exportRepair($params: Input!) {
    results(params: $params)
      @rest(
        type: "案件資料Excel檔"
        path: "/maintain/exportRepair?{args.params}"
      ) {
      id
    }
  }
`

export const getRepairLightQuery = gql`
  query getRepairLight($params: Input!) {
    repairlight(params: $params)
      @rest(type: "RepairLight", path: "/device/light/lookup?{args.params}") {
      data {
        address
        deviceName
        id @export(as: "id")
        lastRepairAt
        sessionName
        info
          @rest(
            type: "StreetLight"
            path: "/device/light/{exportVariables.id}"
          ) {
          ...streetLightInfo
        }
      }
    }
  }
  ${streetLightFramgment}
`

export const getRepairDistrictQuery = gql`
  query getRepairDistrict($params: Input!) {
    session(params: $params)
      @rest(type: "RepairDistEnums", path: "/repairTicket/session") {
      data
    }
  }
`

const useConvertData = (data?: ListRepaisResult) => {
  const { brokenReasonEnumByKey, repairStateEnumByKey } = useRepairEnum()

  const dataSource: DataSource<Repair> = React.useMemo(
    () =>
      convertData({
        data,
        field: 'data',
        converter: (x: Repair, idx) => {
          return {
            ...x,
            stateStr: repairStateEnumByKey[x.state],
            brokenReasonStr: brokenReasonEnumByKey[x.brokenReasonValue],
            createAt: toMoment(x.createAt),
            createAtStr: momentToString(x.createAt),
            lastRepairAt: toMoment(x.lastRepairAt),
            lastRepairAtStr: momentToString(x.lastRepairAt),
            closedAt: toMoment(x.createAt),
            closedAtStr: momentToString(x.closedAt),
          }
        },
      }),
    [data]
  )

  return dataSource
}

export function useRepairs(variables?: ApolloQueryVariables) {
  const { data, ...others } = useQuery<ListRepaisResult>(getRepairsQuery, {
    ...variables,
  })

  const dataSource = useConvertData(data)

  return { ...others, dataSource }
}

export function useUndispatchedRepairs(variables?: ApolloQueryVariables) {
  const { withUndispatchState, loading } = useReportStates()

  const result = useRepairs({
    ...variables,
    ...withUndispatchState,
  })

  return {
    ...result,
    loading: result.loading || loading,
  }
}

export function useDispatchedRepairs(variables?: ApolloQueryVariables) {
  const { withDispatchedState, loading } = useReportStates()

  const result = useRepairs({
    ...variables,
    ...withDispatchedState,
  })

  return {
    ...result,
    loading: result.loading || loading,
  }
}

export function useFinishedRepairs(variables?: ApolloQueryVariables) {
  const { withFinishedState, loading } = useReportStates()

  const result = useRepairs({
    ...variables,
    ...withFinishedState,
  })

  return {
    ...result,
    loading: result.loading || loading,
  }
}

export function useQueryRepairs(variables?: ApolloQueryVariables) {
  const [queryRepairs, { data, ...others }] = useLazyQuery<ListRepaisResult>(
    getRepairsQuery,
    {
      ...variables,
    }
  )

  const dataSource = useConvertData(data)

  return { queryRepairs, data: dataSource, ...others }
}

export function useQueryRepairLight(variables?: ApolloQueryVariables) {
  const [queryRepairLight, { data, ...others }] = useLazyQuery<
    RepairLightResult
  >(getRepairLightQuery, {
    ...variables,
  })

  return {
    queryRepairLight,
    data: pathOr({}, ['repairlight', 'data'], data) as RepairLight,
    ...others,
  }
}

export function useQueryRepair(options: { onCompleted?(data: any): any } = {}) {
  const [queryRepair, { loading, data, ...others }] = useLazyQuery<
    GetRepairResult
  >(getRepairQuery, { ...options })

  const { brokenReasonEnumByKey, repairStateEnumByKey } = useRepairEnum()

  const converter = useConvertStreetLight()

  const dataSource = React.useMemo(() => {
    const rs = pathOr({}, ['repair', 'data'], data) as Repair
    if (isEmpty(rs)) {
      return
    }

    const x = rs

    return {
      ...x,
      stateStr: repairStateEnumByKey[x.state],
      brokenReasonStr: brokenReasonEnumByKey[x.brokenReasonValue],
      createAt: toMoment(x.createAt),
      createAtStr: dateToYYYYMMDDHHMM(new Date(x.createAt as any)),
      lastRepairAt: toMoment(x.lastRepairAt),
      lastRepairAtStr: momentToString(x.lastRepairAt),
      light: {
        ...convertLight(converter)(x),
        raw: path(['light', 'data'], x),
      },
      histories: map(y => {
        return {
          ...y,
          typeStr:
            y.type === 'RH00' ? '通報' : y.type === 'RH03' ? '結案' : '派工',
          createAtStr: dateToYYYYMMDDHHMM(new Date(y.createAt as any)),
        } as RepairHistory
      }, x.histories),
    } as Repair
  }, [data])

  return {
    data: dataSource,
    loadingData: loading,
    queryRepair,
    ...others,
  }
}

export function useCreateRepair(options: MutationOptions) {
  const { loading: creating, handler: handleAdd } = useMutation(
    createRepairQuery,
    options
  )

  return { creating, handleAdd }
}

export function useUpdateRepair(options: MutationOptions = {}) {
  const { loading: updating, handler: handleUpdate } = useMutation(
    updateRepairQuery,
    options
  )

  return [updating, handleUpdate] as Mutation
}

//結案
export function useCloseRepair(options: MutationOptions = {}) {
  const { loading: closing, handler: handleUpdate } = useMutation(
    closeRepairQuery,
    options
  )

  return [closing, handleUpdate] as Mutation
}

//退回
export function useReturnRepair(options: MutationOptions = {}) {
  const { loading: returning, handler: handleReturn } = useMutation(
    rejectRepairQuery,
    options
  )

  return [returning, handleReturn] as Mutation
}

export const useExportRepair = (variables?: ApolloQueryVariables) => {
  const [exportExcel, { loading }] = useLazyQuery(exportRepairQuery, {
    ...variables,
  })

  return { exportExcel, exporting: loading }
}

export const useRepairDist = () => {
  const { data, loading } = useQuery(getRepairDistrictQuery)

  const value = {
    loading,
  }

  if (!data) {
    return {
      ...value,
      repairDistEnums: [] as RepairDistEnums,
    }
  }

  return {
    ...value,
    repairDistEnums: pathOr([], ['session', 'data'], data) as RepairDistEnums,
  }
}
