import React from 'react'
import { useQuery } from '@apollo/react-hooks'
import gql from 'graphql-tag'
import { pathOr, mergeAll, map } from 'ramda'
import { Select, SelectProps } from '@/components/form'
const { compose } = require('ramda')

export type DispatchUnitGroup = {
  id: number
  remark: string
  unitname: string
  unittype: string
  units: DispatchUnit[]
}

export type DispatchUnit = {
  userId: number
  description: string
}

export const getDispatchUnitsQuery = gql`
  query getDipatchUnits {
    dispatchUnits @rest(type: "[DispatchUnit]", path: "/user/workers") {
      data @type(name: "DispatchUnit") {
        userId
        description
      }
    }
  }
`

export const useDispatchUnits = () => {
  const { data, loading, error } = useQuery(getDispatchUnitsQuery, {
    fetchPolicy: 'cache-first',
  })

  const units = pathOr([], ['dispatchUnits', 'data'], data) as DispatchUnit[]

  return {
    loading,
    error,
    units,
    workerById: compose(
      mergeAll,
      map((x: DispatchUnit) => ({ [x.userId]: x }))
    )(units),
  }
}

export const DispatchUnitSelect = React.forwardRef<typeof Select, SelectProps>(
  (props: SelectProps, ref) => {
    const { units } = useDispatchUnits()

    return (
      <Select
        forwardRef={ref}
        allowClear
        placeholder="請選擇派工單位"
        {...props}>
        {units.map(x => (
          <Select.Option key={x.userId} value={x.userId}>
            {x.description}
          </Select.Option>
        ))}
      </Select>
    )
  }
)

export default useDispatchUnits
