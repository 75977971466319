import React from 'react'
import Loading from '@/components/spin/Loading'

const LazyLogin = React.lazy(() => import('./Login'))

export default function Login() {
  return (
    <React.Suspense fallback={<Loading />}>
      <LazyLogin />
    </React.Suspense>
  )
}
