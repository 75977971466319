import React from 'react'
import { Picker } from 'antd-mobile'
import { PickerProps } from 'shared/constants/types'

const PickerComponent: React.ElementType<Partial<
  PickerProps
>> = React.forwardRef((props: Partial<PickerProps>, ref) => {
  const _props = { forwardRef: ref, ...props } as any

  return <Picker title="請選擇" okText="確定" dismissText="取消" {..._props} />
})

PickerComponent.displayName = 'Picker'

export default PickerComponent
