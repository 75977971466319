import React from 'react'

import { useRepairDist } from '@/graphql/maintain/repair'
import { Select } from './Select'
import { DistrictProps } from './District'

export const RepairDistrict = React.forwardRef<typeof Select, DistrictProps>(
  (
    {
      getFieldDecorator,
      onDistChange,
      onChange,
      distFieldName = 'sessionName',
      useDist = true,
      label = false,
      size = 'default',
      // customizeProps,
      distClassName,
      onEditModalVisible,
      newItem,
      disabled = false,
      options,
    }: DistrictProps,
    ref
  ): any => {
    const { repairDistEnums, loading } = useRepairDist()

    let wrapper = React.useMemo(
      () =>
        getFieldDecorator
          ? getFieldDecorator
          : (field: string) => (node: React.ReactNode) => node,
      [getFieldDecorator]
    )

    return (
      <>
        {useDist &&
          wrapper(
            distFieldName,
            options
          )(
            <Select
              disabled={disabled}
              forwardRef={ref}
              loading={loading}
              className={distClassName}
              placeholder="選擇區域"
              allowClear
              size={size}
              onChange={onDistChange}>
              {repairDistEnums.map(x => (
                <Select.Option key={x} value={x}>
                  {x}
                </Select.Option>
              ))}
            </Select>
          )}
      </>
    )
  }
)

export default RepairDistrict
