import React from 'react'
import { SelectProps } from 'shared/components/form'

type Props = SelectProps & {
  field: string
}

export default function useFieldDecorator({
  field,
  initialValue,
  getFieldDecorator,
}: Props) {
  let wrapper = React.useMemo(
    () =>
      getFieldDecorator
        ? getFieldDecorator(field, { initialValue })
        : (node: React.ReactNode) => node,
    [getFieldDecorator, initialValue],
  )

  return wrapper
}
