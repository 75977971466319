import React from 'react'
import { Route, useHistory } from 'react-router-dom'
import { identity, ifElse, empty } from 'ramda'
import { RouteItem } from './useFilterRoutes'
import { useAuth } from './useAuth'
import useMobile from './useMobile'

export default function useApp() {
  const renderRouteItem = React.useCallback((item: RouteItem) => {
    const path = `${item.path}${item.param ? `/${item.param}` : ''}`

    return (
      <Route
        exact
        key={item.id}
        path={path}
        component={
          item.component ? item.component : () => <h1> {item.title} </h1>
        }
      />
    )
  }, [])

  const renderRoute: any = React.useCallback(
    ({ data }: { data: RouteItem[] }) => {
      return data.map((item: RouteItem) => {
        return [
          renderRouteItem(item),
          item.children && renderRoute({ data: item.children }),
          item.hide && renderRoute({ data: item.hide }),
        ]
      })
    },
    [renderRouteItem]
  )

  const { isAuthenticated } = useAuth()
  const history = useHistory()
  const isMobile = useMobile()

  const [currentUrl, setCurrentUrl] = React.useState(history.location.pathname)

  history.listen(() => setCurrentUrl(history.location.pathname))

  const layoutStyle = ifElse(identity, () => ({ width: 1360 }), empty)

  return {
    isMobile,
    isAuthenticated,
    currentUrl,
    layoutStyle,
    renderRoute,
  }
}
