import React from 'react'
import { Router } from 'react-router-dom'
import { ConfigProvider } from 'antd'
import { mergeDeepRight } from 'ramda'
import { ApolloProvider } from '@apollo/react-hooks'
import zhTW from 'antd/lib/locale/zh_TW'
import { ThemeProvider } from 'styled-components'
import createClient from 'shared/graphql/client'
import defaultTheme from 'shared/theme'
import history from 'shared/utils/history'
import { AuthProvider, AuthContext } from 'shared/hooks/useAuth'
import '../index.css'

type Props = {
  theme?: { [key: string]: any }
  locale?: any
}

const renderer = ({ theme = {}, locale = zhTW }: Props = {}) => (
  App: React.FunctionComponent
) => (
  <ThemeProvider theme={mergeDeepRight(defaultTheme, theme)}>
    <ConfigProvider locale={locale}>
      <AuthProvider>
        <AuthContext.Consumer>
          {({ logout, user }) => (
            <ApolloProvider client={createClient({ logout, user })}>
              <Router history={history}>
                <App />
              </Router>
            </ApolloProvider>
          )}
        </AuthContext.Consumer>
      </AuthProvider>
    </ConfigProvider>
  </ThemeProvider>
)

export default renderer
