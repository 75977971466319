import styled from 'styled-components'
import { Input } from 'antd'

const StyledInput = styled(Input)`
  &.ant-input-affix-wrapper .ant-input,
  &.ant-input {
    border-color: ${p => p.theme.whisper};

    &:hover,
    &:focus {
      border-color: ${p => p.theme.primary};
    }

    &:focus {
      box-shadow: none;
    }
  }

  &.ant-input-group-wrapper {
    &.custom-input-addon .ant-input-group-addon {
      background: ${p => p.theme.whisper};
      color: ${p => p.theme.darkGrey};
      font-weight: 500;
    }
  }
`

const Search = styled(Input.Search)`
  &.ant-input-search {
    .ant-input {
      border-color: ${p => p.theme.whisper};

      &:hover,
      &:focus {
        border-color: ${p => p.theme.primary};
      }

      &:focus {
        box-shadow: none;
      }
    }

    .anticon-search {
      font-size: 20px;
      color: ${p => p.theme.searchIcon};
    }
  }
`

const TextArea = styled(Input.TextArea)`
  resize: none;
  border: 1px solid ${p => p.theme.whisper};

  &.ant-input:hover,
  &.ant-input:focus,
  &.ant-input:active {
    border-color: ${p => p.theme.primary};
    box-shadow: 0 0 0 1px ${p => p.theme.primary};
  }
`

export { StyledInput as Input, TextArea, Search }
