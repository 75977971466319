import React from 'react'
import {
  path,
  map,
  join,
  split,
  ifElse,
  not,
  isNil,
  identity,
  length,
  pathOr,
  last,
} from 'ramda'
import * as Icons from '@/components/Icons'
import Exception from '@/components/error/Exception'
import { UserPermission } from '@/graphql/auth'
import useAuth from './useAuth'
const { compose, insert } = require('ramda')

export type RouteItem = {
  id: number
  title: string
  path: string
  component: typeof React.Component
  icon?: React.FunctionComponent
  disabled?: boolean
  param?: string
  children?: RouteItem[]
  hide?: RouteItem[]
  creatable?: boolean
  updatable?: boolean
  deletable?: boolean
  readable?: boolean
  isSubMenu: boolean
}

export type Route = RouteItem & {
  children: Array<RouteItem>
  hide: Array<RouteItem>
  disabled: boolean
}

export function useRoutes(routes: { [key: string]: any }) {
  const { user } = useAuth()

  let _user = {
    ...user,
    funcList: compose(
      insert(1, {
        children: [],
        creatable: null,
        deletable: null,
        icon: 'IconLight',
        id: 100,
        isSubMenu: false,
        name: '路燈換裝列表',
        readable: true,
        updatable: true,
        url: '/street-light-management',
      }),
      insert(1, {
        children: [],
        creatable: null,
        deletable: null,
        icon: 'IconHome',
        id: 101,
        isSubMenu: false,
        name: '安裝進度',
        readable: true,
        updatable: true,
        url: '/street-light-installation-progress',
      }),
      insert(1, {
        children: [
          {
            id: 222,
            createBy: null,
            createTime: null,
            lastUpdateBy: null,
            lastUpdateTime: null,
            parentId: 102,
            name: '材料消耗統計',
            url: '/consumption-record',
            perms: null,
            type: 1,
            icon: 'el-icon-view',
            orderNum: 5,
            delFlag: 0,
            parentName: '消耗品項管理',
            level: 1,
            children: [],
            readable: true,
          },
          {
            id: 227,
            createBy: null,
            createTime: null,
            lastUpdateBy: null,
            lastUpdateTime: null,
            parentId: 102,
            name: '消耗品項管理',
            url: '/consumption-management',
            perms: null,
            type: 1,
            icon: 'el-icon-view',
            orderNum: 5,
            delFlag: 0,
            parentName: '消耗品項管理',
            level: 1,
            children: [],
            readable: true,
          },
        ],
        creatable: null,
        deletable: null,
        icon: 'IconReport',
        id: 102,
        isSubMenu: true,
        name: '耗用紀錄',
        readable: true,
        updatable: false,
      }),
      insert(1, {
        children: [],
        creatable: null,
        deletable: null,
        icon: 'IconUser',
        id: 104,
        isSubMenu: false,
        name: '管理使用者',
        readable: true,
        updatable: true,
        url: '/user-list',
      }),
      pathOr([], ['funcListxxx'])
    )(user),
  }

  return compose(
    map((x: UserPermission) => ({
      ...x,
      title: x.name,
      path: x.url,
      icon: ifElse(
        compose(not, isNil, path([x.icon])),
        () => (Icons as any)[x.icon],
        identity
      )(Icons),
      ...(x.isSubMenu
        ? {}
        : {
            component: pathOr(Exception, [
              compose(
                join(''),
                map((s: string) => s.charAt(0).toUpperCase() + s.slice(1)),
                split('-'),
                last,
                split('/'),
                pathOr('', ['url'])
              )(x),
            ])(routes),
          }),
      children: compose(
        map((c: UserPermission) => ({
          ...c,
          title: c.name,
          path: c.url,
          component: pathOr(Exception, [
            compose(
              join(''),
              map((s: string) => s.charAt(0).toUpperCase() + s.slice(1)),
              split('-'),
              ifElse(v => length(v) === 3, pathOr('', [2]), pathOr('', [1])),
              // pathOr('', [2]),
              split('/'),
              pathOr('', ['url'])
            )(c),
          ])(routes),
        })),
        pathOr([], ['children'])
      )(x),
    })),
    pathOr([], ['funcList'])
  )(_user)
}

export default useRoutes
