import React from 'react'
import styled from 'styled-components'

function AppHeader() {
  return <RectangleBlock />
}

const RectangleBlock = styled.div`
  height: 4px;
  width: 100%;
  background: ${({ theme }) => theme.primary};
`

export default React.memo(AppHeader)
