import React, { Suspense } from 'react'
import { Route, Switch } from 'react-router-dom'
import cx from 'classnames'
import { Layout } from 'antd'
import ErrorBoundary from '@/components/error/ErrorBoundary'
import Loading from '@/components/spin/Loading'
import Exception from '@/components/error/Exception'
import { useQueryWebSetting } from '@/graphql/setting'
import { AlarmProvider } from '@/hooks/useAlarmDrawer'
import useTitle from '@/hooks/useTitle'
import useApp from '@/hooks/useApp'
import useRoutes from 'src/hooks/useRoutes'
import StreetLightManagement from 'src/containers/StreetLightManagement'
import AppHeader from 'src/components/AppHeader'
import SideMenu from 'src/components/SideMenu'
import Login from 'src/containers/Login'
import GlobalStyles from './GlobalStyles'

function App() {
  const routes = useRoutes()

  const { isAuthenticated, isMobile, layoutStyle, renderRoute } = useApp()

  const { called, loading, web } = useQueryWebSetting()

  useTitle(!isAuthenticated || !web.name ? '路燈換裝管理系統' : web.name)

  if (!called || loading) {
    return <Loading />
  }

  return (
    <ErrorBoundary>
      <AlarmProvider>
        <GlobalStyles />
        {!isAuthenticated && (
          <Layout>
            <Login />
          </Layout>
        )}
        {isAuthenticated && (
          <Layout style={layoutStyle(isMobile)}>
            <AppHeader />
            <Layout className={cx('bg-greyF9')}>
              <SideMenu />
              <Layout.Content>
                <Suspense fallback={<Loading />}>
                  <Switch>
                    <Route exact path="/" component={StreetLightManagement} />
                    {renderRoute({ data: routes })}
                    <Route component={Exception} />
                  </Switch>
                </Suspense>
              </Layout.Content>
            </Layout>
          </Layout>
        )}
      </AlarmProvider>
    </ErrorBoundary>
  )
}

export default React.memo(App)
