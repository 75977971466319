import React from 'react'
import styled, { css } from 'styled-components'
import { Radio } from 'antd'
import { RadioProps } from 'antd/lib/radio'

const StyledRadio = styled(Radio)`
  &.ant-radio-wrapper {
    &.is--vertical-align {
      display: block;
      height: 30px;
      line-height: 30px;
    }

    &.is--mobile {
      .ant-radio-inner {
        top: 2px;
      }
    }

    ${p => {
      const color =
        ((p && p.className) || '').indexOf('is--secondary') > -1
          ? 'secondary'
          : 'primary'

      return css`
        .ant-radio-inner::after {
          background-color: ${p => p.theme[color]};
        }

        &.ant-radio-checked::after {
          border: 1px solid ${p => p.theme[color]};
        }

        .ant-radio-checked .ant-radio-inner,
        .ant-radio:hover .ant-radio-inner,
        .ant-radio:focus .ant-radio-inner,
        .ant-radio:active .ant-radio-inner {
          border-color: ${p => p.theme[color]};
        }
      `
    }};

    .ant-radio {
      top: -2px;
    }

    .ant-radio-inner {
      border-color: ${p => p.theme.grey};
    }

    span.ant-radio + * {
      padding-left: 10px;
      color: ${p => p.theme.lightDark};
    }
  }
`

export default class RadioComponent extends React.Component<
  RadioProps & { css?: React.CSSProperties },
  any
> {
  public static Group = Radio.Group

  public render() {
    return <StyledRadio {...this.props} />
  }
}
