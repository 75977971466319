import React from 'react'
import gql from 'graphql-tag'
import { useQuery } from '@apollo/react-hooks'
import { map, pathOr, mergeAll } from 'ramda'
import Picker from '@/components/common/Picker'
import PickerInput from '@/components/common/PickerInput'
import useFieldDecorator from '@/hooks/useFieldDecorator'
import { PickerProps } from '@/constants/types'
const { compose } = require('ramda')

const defaultDistEnums = [
  { sessionId: 1, sessionNo: '6500200', sessionName: '三重區' },
  { sessionId: 2, sessionNo: '6500500', sessionName: '新莊區' },
  { sessionId: 3, sessionNo: '6501000', sessionName: '淡水區' },
  { sessionId: 4, sessionNo: '6501100', sessionName: '汐止區' },
  { sessionId: 5, sessionNo: '6501400', sessionName: '蘆洲區' },
  { sessionId: 6, sessionNo: '6501500', sessionName: '五股區' },
  { sessionId: 7, sessionNo: '6501600', sessionName: '泰山區' },
  { sessionId: 8, sessionNo: '6501700', sessionName: '林口區' },
  { sessionId: 9, sessionNo: '6502100', sessionName: '三芝區' },
  { sessionId: 10, sessionNo: '6502200', sessionName: '石門區' },
  { sessionId: 11, sessionNo: '6502300', sessionName: '八里區' },
  { sessionId: 12, sessionNo: '6502700', sessionName: '金山區' },
  { sessionId: 13, sessionNo: '6502800', sessionName: '萬里區' },
]

export type Dist = {
  sessionId: number
  sessionName: string
  sessionNo: string
}

export type DistEnums = Array<Dist>

export type Area = {
  sessionId: number
  villageId: number
  villageName: string
  villageNo: string
}

export type AreaEnums = Array<Area>

export const getAllDistQuery = gql`
  query getAllDist($params: Input!) {
    results(params: $params)
      @rest(type: "DistEnums", path: "/session/sessions") {
      data
    }
  }
`

export const getAllAreaQuery = gql`
  query getAlarmType($params: Input!) {
    results(params: $params) @rest(type: "any", path: "/mobile/villages") {
      data
    }
  }
`

export const useVillages = () => {
  const { data, loading } = useQuery(getAllDistQuery, {
    fetchPolicy: 'no-cache',
  })

  const value = {
    loading,
  }

  if (!data) {
    return {
      ...value,
      distById: {},
      distEnums: [] as DistEnums,
    }
  }

  const distEnums = pathOr(
    defaultDistEnums,
    ['results', 'data'],
    data
  ) as DistEnums

  return {
    ...value,
    distEnums,
    distById: compose(
      mergeAll,
      map((x: Dist) => ({ [x.sessionNo]: x }))
    )(distEnums) as { [key: string]: Dist },
  }
}

export const useAreas = () => {
  const { data, loading } = useQuery(getAllAreaQuery, {
    fetchPolicy: 'no-cache',
  })

  const value = {
    loading,
  }

  if (!data) {
    return {
      ...value,
      areaById: {},
      areaEnums: [] as AreaEnums,
    }
  }

  const areaEnums = pathOr([], ['results', 'data'], data) as AreaEnums

  return {
    ...value,
    areaEnums,
    areaById: compose(
      mergeAll,
      map((x: Area) => ({ [x.villageNo]: x }))
    )(areaEnums) as { [key: string]: Area },
  }
}

export const DistMobileSelect: React.ElementType<PickerProps> = React.forwardRef<
  typeof Picker,
  PickerProps
>(
  (
    {
      getFieldDecorator,
      initialValue,
      className,
      placeholder = '選擇區',
      ...others
    }: PickerProps,
    ref
  ): any => {
    const { distEnums } = useVillages()

    let wrapper = useFieldDecorator({
      field: 'sessionName',
      getFieldDecorator,
      initialValue,
    })

    const props = {
      forwardRef: ref,
      data: map(x => ({ label: x.sessionName, value: x.sessionNo }), distEnums),
      ...others,
    }

    return wrapper(
      <Picker {...props} cols={1}>
        <PickerInput placeholder={placeholder} className={className} />
      </Picker>
    )
  }
)

export default useVillages
