import gql from 'graphql-tag'
import moment from 'moment'
import { useQuery as useApolloQuery } from '@apollo/react-hooks'
import { pathOr, forEach } from 'ramda'
import { pathBuilder, convertData, skipState } from '@/graphql/utils'
import {
  MaintainState,
  maintainStateFragment,
} from '@/graphql/fragments/maintainStateFragment'
import {
  Dispatch,
  dispatchFragment,
} from '@/graphql/fragments/dispatchFragment'
import useReportStates from '@/graphql/reportStates'
import { useQuery, useLazyQuery } from '@/hooks/useQuery'
import { useMutation, MutationOptions } from '@/hooks/useMutation'
import { ApolloQueryVariables, Mutation } from '@/constants/types'
import { toMoment, momentToString } from '@/utils/webHelper'
import { getContractQuery, Contract } from '../contract'

export type DispatchItem = {
  contractid: string
  dispatchid: string
  id: number | string
  item: string
  note: string
  price: number
  quantity: number
  totalprice: number
  unit: string
  isdelete?: boolean
}

export type ContractDispatch = {
  contractid: string
  dispatchdate: moment.Moment
  dispatchdateStr: string
  dispatchid: string
  id: number
  lightcode: string
  note: string
  price: number
  quality: number
  quantity: number
  repairid: string
  safety: number
  shlifi: number
  supervision: number
  supplier: number
  totalprice: number
  unit: string
  valuetax: number
  scheduledate: moment.Moment
  scheduledateStr: string
  havematerial: boolean
  contract: Contract
}

export type DispatchMaterial = {
  dispatchid: string
  id: number
  isenable: boolean
  materialid: number
  quantityused: number
}

export type ListDispatchsResult = {
  results: {
    repairs: Dispatch[]
    total: number
  }
}

export type GetDispatchResult = {
  dispatchDetail: Dispatch
}

export const getDispatchsPathBuilder = pathBuilder('/maintain/getDispatchs')

export const getDispatchsQuery = gql`
  query getDispatchs($params: Input!) {
    results(params: $params)
      @rest(type: "Dispatch", pathBuilder: $pathBuilder) {
      repairs @type(name: "Dispatch") {
        ...dispatchInfo
      }
      total
    }
  }
  ${dispatchFragment}
`

export const updateDispatchQuery = gql`
  mutation updateDispatch($input: Input!) {
    results(input: $input)
      @rest(type: "Dispatch", method: "PUT", path: "/maintain/updateDispatch") {
      error
      message
      path
      status
      timestamp
      result
    }
  }
`

export const getDispatchStatesQuery = gql`
  query getDispatchStates {
    dispatchStates @rest(type: "MaintainState", path: "/basic/dispatchState") {
      ...maintainStateInfo
    }
  }
  ${maintainStateFragment}
`

export const createDispatchQuery = gql`
  mutation addDispatch($input: Repair) {
    results(input: $input)
      @rest(type: "Repair", method: "POST", path: "/repairTicket/dispatch") {
      data
      errorMsg
    }
  }
`

export const createContractDispatchQuery = gql`
  mutation createContractDispatch($input: Repair) {
    results(input: $input)
      @rest(
        type: "Repair"
        method: "POST"
        path: "/maintain/newContractDispatch"
      ) {
      data
      errorMsg
    }
  }
`

export const createDispatchMaterialsQuery = gql`
  mutation createDispatchMaterial($input: Input!) {
    results(input: $input)
      @rest(
        type: "[DispatchMaterial]"
        method: "POST"
        path: "/maintain/newDispatchMaterial"
      ) {
      code
      msg
      data
    }
  }
`

export const updateDispatchMaterialsQuery = gql`
  mutation updateDispatchMaterial($input: Input!) {
    reuslts(input: $input)
      @rest(
        type: "[DispatchMaterial]"
        method: "PUT"
        path: "/maintain/updateDispatchMaterial"
      ) {
      error
      message
      path
      status
      timestamp
      result
    }
  }
`

export const createDispatchItemsQuery = gql`
  mutation createDispatchItems($input: Input!) {
    results(input: $input)
      @rest(
        type: "[DispatchItem]"
        method: "POST"
        path: "/maintain/newDispatchItems"
      ) {
      code
      msg
      data
    }
  }
`

export const updateDispatchItemsQuery = gql`
  mutation updateDispatchItems($input: Input!) {
    results(input: $input)
      @rest(
        type: "[DispatchItem]"
        method: "PUT"
        path: "/maintain/updateDispatchItems"
      ) {
      code
      msg
      data
    }
  }
`

export const useConvertDispatch = (data?: ListDispatchsResult) => {
  const { reportStateById } = useReportStates()

  const dataSource = convertData<Dispatch>({
    data,
    field: 'repairs',
    converter: x => {
      return {
        ...x,
        dispatchdate: toMoment(x.dispatchdate),
        dispatchDateStr: momentToString(x.dispatchdate),
        scheduleddate: toMoment(x.scheduleddate),
        scheduledDateStr: momentToString(x.scheduleddate),
        finisheddate: toMoment(x.finisheddate),
        finishedDateStr: momentToString(x.finisheddate),
        statename: pathOr(x.state, [x.state, 'state'], reportStateById),
      }
    },
  })

  return dataSource
}

export function useDispatchs(variables?: ApolloQueryVariables) {
  const { data, ...others } = useQuery<ListDispatchsResult>(getDispatchsQuery, {
    ...variables,
    pathBuilder: getDispatchsPathBuilder,
  })

  const dataSource = useConvertDispatch(data)

  return {
    ...others,
    dataSource,
  }
}

export function useUpdateDispath(options: MutationOptions = {}) {
  const { loading, handler } = useMutation(updateDispatchQuery, options)

  return [loading, handler] as [boolean, (values: unknown) => void]
}

export const useDispatchStates = () => {
  const {
    data: { dispatchStates = [] } = {},
    loading,
    error,
  } = useApolloQuery(getDispatchStatesQuery, { fetchPolicy: 'cache-first' })

  let dispatchStateById: { [key: string]: MaintainState } = {}

  forEach((x: MaintainState) => (dispatchStateById[x.code] = x), dispatchStates)

  // DS02
  const dispatchToSupplierState = skipState({
    field: 'code',
    name: 'state',
    value: '已派監造',
  })(dispatchStates)

  // DS01
  const dispatchedState = skipState({
    field: 'code',
    name: 'state',
    value: '已派工',
  })(dispatchStates)

  // DS03
  const closeDispatchState = skipState({
    field: 'code',
    name: 'state',
    value: '結案',
  })(dispatchStates)

  return {
    dispatchStateEnums: dispatchStates as MaintainState[],
    dispatchStateById,
    dispatchToSupplierState,
    closeDispatchState,
    dispatchedState,
    loading,
    error,
  }
}

export function useDispatchItems(variables?: ApolloQueryVariables) {
  const [queryDispatchItems, { data, ...others }] = useLazyQuery(
    getContractQuery,
    {
      ...variables,
    }
  )

  const dataSource = convertData<DispatchItem>({
    data,
    field: ['contractDetail', 'tbcontractdetails'],
    converter: x => ({
      ...x,
    }),
  })

  return { ...others, queryDispatchItems, dataSource }
}

export function useCreateDispatch(options: MutationOptions) {
  const { loading: dispatching, handler: handleDispatch } = useMutation(
    createDispatchQuery,
    options
  )

  return [dispatching, handleDispatch] as Mutation
}

export function useCreateContractDispatch(options: MutationOptions) {
  const { loading, handler } = useMutation(createContractDispatchQuery, options)

  return [loading, handler] as Mutation
}

export function useCreateDispatchMaterials(options: MutationOptions = {}) {
  const { loading, handler } = useMutation(
    createDispatchMaterialsQuery,
    options
  )

  return [loading, handler] as Mutation
}

export function useUpdateDispatchMaterials(options: MutationOptions = {}) {
  const { loading, handler } = useMutation(
    updateDispatchMaterialsQuery,
    options
  )

  return [loading, handler] as Mutation
}

export function useCreateDispatchItems(options: MutationOptions = {}) {
  const { loading, handler } = useMutation(createDispatchItemsQuery, options)

  return [loading, handler] as Mutation
}

export function useUpdateDispatchItems(options: MutationOptions = {}) {
  const { loading, handler } = useMutation(updateDispatchItemsQuery, options)

  return [loading, handler] as Mutation
}
