import React from 'react'
import styled, { css } from 'styled-components'
import { Button } from 'antd'
import { ButtonProps } from 'antd/lib/button'
import { ActionTypes } from 'shared/constants/enums'
import useRouteCRUDAuthCheck from 'shared/hooks/useRouteCRUDAuthCheck'
import * as Icons from 'shared/components/Icons'

interface Props extends Omit<ButtonProps, 'icon'> {
  children: React.ReactNode
  circle?: boolean
  color?: string
  css?: React.CSSProperties
  icon?: string | React.ReactNode
  onClick?: (evt: React.MouseEvent<HTMLElement>) => any
  actionType?: ActionTypes
}

const StyledButton = styled(({ circle, ...props }) => <Button {...props} />)<
  Props
>`
  &.ant-btn {
    min-width: 96px;
    border-radius: 4px;
    font-weight: 500;

    .anticon {
      margin-right: 6px;
    }

    svg {
      width: 20px;
      height: 20px;
    }

    &.ant-btn-sm svg {
      width: 16px;
      height: 16px;
    }

    ${p =>
      p.color === 'primary' &&
      css`
        color: ${p => p.theme.btn.text};
        background: ${p => p.theme.btn.bg};
        border: 1px solid ${p => p.theme.btn.bg};

        &:hover,
        &:focus,
        &:active {
          border: 1px solid ${p => p.theme.btn.bgHover};
          background: ${p => p.theme.btn.bgHover};
        }
      `};

    ${p =>
      p.color === 'warning' &&
      css`
        color: #fff;
        background: ${p => p.theme.gamboge};
        border: 1px solid ${p => p.theme.gamboge};

        &:hover,
        &:focus,
        &:active {
          color: #fff;
          border: 1px solid ${p => p.theme.gamboge};
          background: ${p => p.theme.gamboge};
        }
      `};

    ${p =>
      p.color === 'outline' &&
      css`
        min-width: 104px;
        padding: 0 8px;
        color: ${p => p.theme.btn.bg};
        border: 1.3px solid ${p => p.theme.btn.bg};
        background: #fff;

        &:hover,
        &:focus,
        &:active {
          color: ${p => p.theme.btn.bg};
          background: ${p => p.theme.btn.outlineHover};
          border: 1.3px solid ${p => p.theme.btn.bg};
        }

        &.is--warning {
          color: ${p => p.theme.warning};
          border: 1.3px solid ${p => p.theme.warning};

          &:hover,
          &:focus,
          &:active {
            color: ${p => p.theme.warning};
            background: #f1f1f1;
            border: 1.3px solid ${p => p.theme.warning};
          }
        }

        &.is--yellow {
          color: ${p => p.theme.gamboge};
          border: 1.3px solid ${p => p.theme.gamboge};

          &:hover,
          &:focus,
          &:active {
            color: ${p => p.theme.gamboge};
            background: #f1f1f1;
            border: 1.3px solid ${p => p.theme.gamboge};
          }
        }
      `};

    ${p =>
      p.color === 'danger' &&
      css`
        color: ${p => p.theme.danger};
        border: 1.3px solid ${p => p.theme.danger};
        background: #fff;

        &:hover,
        &:focus,
        &:active {
          color: ${p => p.theme.danger};
          background: #fff6e4;
          border: 1.3px solid ${p => p.theme.danger};
        }
      `};

    ${p =>
      p.color === 'secondary' &&
      css`
        color: #fff;
        border-radius: 16px;
        background: ${p => p.theme.btn.bg};
        border: 1px solid ${p => p.theme.btn.bg};
        height: 24px;

        .icon-location > svg > g path:nth-child(2) {
          fill: #fff;
        }

        &:hover,
        &:focus,
        &:active {
          border: 1px solid ${p => p.theme.btn.bgHover};
          background: ${p => p.theme.btn.bgHover};
        }

        svg {
          width: 16px;
          height: 16px;
        }
      `};

    ${p =>
      p.circle &&
      css`
        border-radius: 16px;
      `};

    ${p =>
      p.type === 'link' &&
      css`
        background: transparent;
        border-color: transparent;
        color: ${p.theme.btn.linkColor};

        > span {
          text-decoration: underline;
        }

        &:hover {
          background: transparent;
          border-color: transparent;
          color: ${p.theme.btn.linkHover};
        }
      `};

    ${p =>
      p.size === 'small' &&
      css`
        line-height: 1.2;
      `}

    ${p =>
      p.color === 'info' &&
      css`
        color: #fff;
        background: ${p => p.theme.btn.info};
        border: 1px solid ${p => p.theme.btn.info};

        &:hover,
        &:focus,
        &:active {
          border: 1px solid ${p => p.theme.btn.infoHover};
          background: ${p => p.theme.btn.infoHover};
          color: ${p => p.theme.btn.text};
        }
      `};
  }

  &.ant-btn.ant-btn-loading:not(.ant-btn-circle):not(.ant-btn-circle-outline):not(.ant-btn-icon-only) {
    padding-left: 20px;
  }

  &.ant-btn[disabled] {
    svg > g path:nth-child(2) {
      transition: fill 0.4s ease;
      fill: darkgrey;
    }
  }
`

export { StyledButton as Butotn }

export default function ButtonComponent({
  icon,
  children,
  loading,
  actionType,
  ...props
}: Props) {
  const [, checkIsAuthenticated] = useRouteCRUDAuthCheck()

  if (actionType && !checkIsAuthenticated(actionType)) {
    return null
  }

  return (
    <StyledButton {...props} loading={loading}>
      {(() => {
        if (icon && !loading) {
          let Icon

          const isElement = React.isValidElement(icon)

          if (!isElement) {
            Icon = icon
          }

          if (typeof icon === 'string') {
            const nameCapitalized = icon.charAt(0).toUpperCase() + icon.slice(1)
            const iconName = 'Icon' + nameCapitalized
            Icon = (Icons as any)[iconName]
          }

          return (
            <div className="l-flex--center">
              {isElement ? icon : <Icon className="c-icon is--secondary" />}
              {children}
            </div>
          )
        }

        return children
      })()}
    </StyledButton>
  )
}
