import React from 'react'
import { isNil } from 'ramda'
import Store from 'store'
import { User } from '@/graphql/auth'
import history from '@/utils/history'

export type LogoutFn = (params?: { onCompleted?: () => any }) => any

export type AuthContextType = {
  logout: LogoutFn
  isAuthenticated: boolean
  user: User
  setUser: React.Dispatch<React.SetStateAction<User | null>>
  setIsAuthenticated: React.Dispatch<React.SetStateAction<boolean>>
  userColumn: Array<any>
  setUserColumn: React.Dispatch<React.SetStateAction<any>>
}

export const AuthContext = React.createContext<AuthContextType>({
  isAuthenticated: false,
  user: {} as User,
  logout: () => ({}),
  setUser: (value: React.SetStateAction<User | null>) => ({}),
  setIsAuthenticated: (value: React.SetStateAction<boolean>) => ({}),
  userColumn: [],
  setUserColumn: (value: React.SetStateAction<any>) => ({}),
})

const _user = Store.get('scntpc_user')
const _token = Store.get('scntpc_token')
const _nonce = Store.get('scntpc_nonce')
const _columns = Store.get('scntpc_columns')

export function AuthProvider(props: any) {
  const [isAuthenticated, setIsAuthenticated] = React.useState(
    !isNil(_token) && !isNil(_user) && !isNil(_nonce)
  )
  const [user, setUser] = React.useState<User>(_user)
  const [userColumn, setUserColumn] = React.useState<any>(_columns)

  React.useEffect(() => {
    Store.set('scntpc_columns', userColumn)
  }, userColumn)

  const logout = React.useCallback(({ onCompleted } = {}) => {
    Store.remove('scntpc_token')
    Store.remove('scntpc_nonce')
    Store.remove('scntpc_user')
    Store.remove('scntpc_columns')
    setIsAuthenticated(false)

    history.push('/')

    if (onCompleted) {
      onCompleted()
    }
  }, [])

  const value = React.useMemo(
    () => ({
      isAuthenticated,
      setIsAuthenticated,
      setUser,
      logout,
      user,
      setUserColumn,
      userColumn,
    }),
    [isAuthenticated, JSON.stringify(user)] // eslint-disable-line
  )

  return <AuthContext.Provider value={value} {...props} />
}

export function useAuth() {
  const context = React.useContext(AuthContext)
  if (context === undefined) {
    throw new Error(`useAuth must be used within a AuthProvider`)
  }
  return context
}

export default useAuth
