import {
  find,
  propEq,
  forEach,
  addIndex,
  has,
  map,
  isEmpty,
  ifElse,
  identity,
  either,
  compose,
  not,
  is,
  isNil as RisNil,
  path as Rpath,
  head,
} from 'ramda'
import moment from 'moment'
import Store from 'store'
import { appUrl, isMobile, apiBaseUrl } from 'shared/env'

export const shouldRedirect = () => {
  if (window.location.pathname === '/') {
    window.location.href = appUrl
    return
  }
}

export function queryString(search: string): { [key: string]: any } {
  if (!search) {
    search = window.location.search
  }

  const params = new URLSearchParams(search)

  let result: object | any = {}

  params.forEach((value: string, key: string) => (result[key] = value))

  return result
}

export function uuid() {
  let d = Date.now()
  if (
    typeof performance !== 'undefined' &&
    typeof performance.now === 'function'
  ) {
    d += performance.now()
  }
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
    const r = (d + Math.random() * 16) % 16 | 0
    d = Math.floor(d / 16)
    return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16)
  })
}

export function isUUID(uuid: string) {
  return /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i.test(
    uuid
  )
}

export function toBase64(file: File | Blob) {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader()
    fileReader.addEventListener('load', () => resolve(fileReader.result))
    fileReader.onerror = reject
    fileReader.readAsDataURL(file)
  })
}

export function toBinaryString(file: File | Blob) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onload = (evt: any) => resolve(btoa(evt.target.result))
    reader.onerror = reject
    reader.readAsBinaryString(file)
  })
}

export function selectFile({
  accept = '*',
  callback,
}: {
  accept?: string
  callback: (files: FileList | null) => any
}) {
  const input = document.createElement('input')
  input.setAttribute('type', 'file')
  input.setAttribute('class', 'hidden')
  input.setAttribute('accept', accept)
  input.addEventListener('change', () => callback(input.files))
  input.click()
}

export const findValue = (value?: number | string, field = 'pid') =>
  find(propEq(field, value))

export const forEachIndexed = addIndex(forEach)
export const mapIndexed: any = addIndex(map)

export const isNil = either(isEmpty, RisNil)

export const hasValue = ifElse(isNil, identity, has('value'))

export const hasToken = ifElse(isNil, identity, has('token'))

export const hasPath = (path: Array<string | number>) =>
  compose(not, isNil, Rpath(path))

export const getPickerValue = ifElse(is(Array), head, identity)

export const toMoment = (data: any) => {
  if (!data) {
    return data
  }

  if (moment.isMoment(data)) {
    return data
  }

  let result

  if (is(Number, data) || is(String, data) || is(Date, data)) {
    result = moment(data)
  }

  if (result?.isValid()) {
    return result
  }

  return data
}

export const momentToString = (
  date: moment.Moment | undefined | string,
  format: string = 'YYYY/MM/DD HH:mm:ss'
) => {
  return moment.isMoment(date)
    ? date.format(format)
    : is(String, date) || is(Date, date) || is(Number, date)
    ? toMoment(date)
      ? (toMoment(date) as moment.Moment).format(format)
      : ''
    : ''
}

export const momentToISOString = (date: moment.Moment | undefined | string) => {
  return moment.isMoment(date)
    ? date.toISOString()
    : is(String, date)
    ? toMoment(date)
      ? (toMoment(date) as moment.Moment).toISOString()
      : ''
    : ''
}

export const getRangeDate = (
  date: [moment.Moment, moment.Moment],
  dateFormat?: string
) => {
  const [startDate, stopDate] = date

  return [
    startDate
      ? dateFormat
        ? startDate.format(dateFormat)
        : startDate.valueOf()
      : undefined,
    stopDate
      ? dateFormat
        ? stopDate.format(dateFormat)
        : stopDate.valueOf()
      : undefined,
  ]
}

export const toThousandSeparator = (number: number | string) => {
  if (number && (typeof number === 'number' || isNaN(number as any))) {
    return number.toLocaleString()
  }
  return number
}

export const capitalize = (s: string) => {
  if (typeof s !== 'string') return ''
  return s.charAt(0).toUpperCase() + s.slice(1)
}

export const downloadFile = ({
  blob,
  filename,
}: {
  blob: Blob
  filename?: string
}) => {
  const a = document.createElement('a')
  let objectUrl = window.URL.createObjectURL(blob)

  a.href = objectUrl
  a.download = `${moment().format('YYYYMMDDHHMMss')}_${filename}.xlsx`
  a.click()
}

export function downloadCSV({
  text,
  filename,
}: {
  text: string
  filename: string
}) {
  const dataStr = 'data:text/csv;charset=utf-8,' + encodeURIComponent(text)
  const downloadAnchorNode = document.createElement('a')
  downloadAnchorNode.setAttribute('href', dataStr)
  downloadAnchorNode.setAttribute(
    'download',
    `${moment().format('YYYYMMDDHHMMss')}_${filename}.csv`
  )
  document.body.appendChild(downloadAnchorNode) // required for firefox
  downloadAnchorNode.click()
  downloadAnchorNode.remove()
}

export function downloadAsJson({
  json,
  filename,
}: {
  json: object
  filename: string
}) {
  const dataStr =
    'data:text/json;charset=utf-8,' + encodeURIComponent(JSON.stringify(json))
  const downloadAnchorNode = document.createElement('a')
  downloadAnchorNode.setAttribute('href', dataStr)
  downloadAnchorNode.setAttribute('download', filename + '.json')
  document.body.appendChild(downloadAnchorNode) // required for firefox
  downloadAnchorNode.click()
  downloadAnchorNode.remove()
}

export function loadImage(value: string): Promise<string> {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest()
    xhr.responseType = 'blob'
    xhr.open('GET', value)
    xhr.setRequestHeader('X-Auth-Token', Store.get('scntpc_token'))
    xhr.setRequestHeader('X-Auth-Nonce', Store.get('scntpc_nonce'))
    xhr.send()

    xhr.onload = () => {
      if (xhr.readyState === XMLHttpRequest.DONE && xhr.status === 200) {
        const objectURL = window.URL.createObjectURL(xhr.response)
        if (objectURL) {
          return resolve(objectURL)
        }
      }
    }

    xhr.onerror = error => reject(error)
  })
}

export function getDiffHour(
  start: moment.Moment | undefined,
  end?: moment.Moment
) {
  if (!start || !end) {
    return 0
  }

  let diff = (end.toDate().getTime() - start.toDate().getTime()) / 1000
  diff /= 60 * 60

  return diff
}

export function formatDate(
  value: string | number | moment.Moment | Date,
  format = 'YYYY.MM.DD'
) {
  if (!value) {
    return value
  }

  return moment(value).format(format)
}

const getDatePart = (date: Date) => {
  const M = date.getMonth() + 1 // getMonth() is zero-based
  const dd = date.getDate()
  const hh = date.getHours()
  const mm = date.getMinutes()
  const ss = date.getSeconds()

  return [
    date.getFullYear(),
    (M > 9 ? '' : '0') + M,
    (dd > 9 ? '' : '0') + dd,
    (hh > 9 ? '' : '0') + hh,
    (mm > 9 ? '' : '0') + mm,
    (ss > 9 ? '' : '0') + ss,
  ]
}

export function dateToYYYYMMDD(date?: Date, sep: string = '-') {
  if (!date) {
    return date
  }

  const [yyyy, mm, dd] = getDatePart(date)

  return [yyyy, mm, dd].join(sep)
}

export function dateToYYYYMMDDHHMM(date?: Date, sep: string = '-') {
  if (!date) {
    return date
  }

  const [yyyy, MM, dd, hh, mm] = getDatePart(date)

  return `${[yyyy, MM, dd].join(sep)} ${[hh, mm].join(':')}`
}

export const toDateString = (
  data: any,
  format: string = 'YYYY.MM.DD HH:mm:ss'
) => {
  const m = toMoment(data)

  if (moment.isMoment(m)) {
    return m.format(format)
  }

  return ''
}

export function getNumberFromString(str?: string) {
  if (!str) {
    return str
  }
  return str.replace(/[^\d.]/g, '')
}

export const getMinDateTime = (v: moment.Moment | string | number) => {
  if (!v) {
    return
  }

  let _v: typeof v = v

  if (is(String, _v) || is(Number, v)) {
    _v = moment(_v)
  }

  if (!moment.isMoment(_v) || !_v.isValid()) {
    return
  }

  return _v.set('h', 0).set('m', 0).set('s', 0).valueOf()
}

export const getMaxDateTime = (v: moment.Moment | string | number) => {
  if (!v) {
    return
  }

  let _v: typeof v = v

  if (is(String, _v) || is(Number, v)) {
    _v = moment(_v)
  }

  if (!moment.isMoment(_v) || !_v.isValid()) {
    return
  }

  return _v.set('h', 23).set('m', 59).set('s', 59).valueOf()
}

function dataURItoBlob(dataURL: any) {
  var BASE64_MARKER = ';base64,'
  if (dataURL.indexOf(BASE64_MARKER) == -1) {
    var parts = dataURL.split(',')
    var contentType = parts[0].split(':')[1]
    var raw = decodeURIComponent(parts[1])
    return new Blob([raw], { type: contentType })
  }
  var parts = dataURL.split(BASE64_MARKER)
  var contentType = parts[0].split(':')[1]
  var raw = window.atob(parts[1])
  var rawLength = raw.length

  var uInt8Array = new Uint8Array(rawLength)

  for (var i = 0; i < rawLength; ++i) {
    uInt8Array[i] = raw.charCodeAt(i)
  }

  return new Blob([uInt8Array], { type: contentType })
}

export const rotateBase64Image = (
  srcBase64: any,
  degrees: number,
  callback: any
) => {
  const canvas = document.createElement('canvas')
  const ctx: any = canvas.getContext('2d')
  const image = new Image()

  image.onload = function () {
    canvas.width = degrees % 180 === 0 ? image.width : image.height
    canvas.height = degrees % 180 === 0 ? image.height : image.width

    ctx.translate(canvas.width / 2, canvas.height / 2)
    ctx.rotate((degrees * Math.PI) / 180)
    ctx.drawImage(image, image.width / -2, image.height / -2)

    const dataUrl = canvas.toDataURL('image/jpeg', 1.0)

    callback(dataUrl)
  }

  image.src = srcBase64
}
