import gql from 'graphql-tag'
import moment from 'moment'
import { pluck, join } from 'ramda'
import { convertData as convertAPIData } from './utils'
import { useLazyQuery } from '@/hooks/useQuery'
import {
  ApolloQueryVariables,
  QueryResult,
  StreetLight,
} from '@/constants/types'
import { momentToString, toMoment } from '@/utils/webHelper'
import { useQueryRepairs } from './maintain'
import { useReportStates } from './reportStates'
const { compose } = require('ramda')

export type Alarm = {
  address: string
  alarmType: number
  id: number
  deviceId: string
  deviceStatus: number
  isAlarm: boolean
  isDeviceFail: boolean
  isRepair: boolean
  isSmartControl: boolean
  isWarning: boolean
  lampWatt: number | string
  lampStatus: -1 | 1 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 13 | 14 | 100
  lat: number
  light: StreetLight
  lightNo: string
  lightType: string
  lon: number
  remark: string
  updateTime: moment.Moment
  updateTimeStr: string
}

export enum AlarmModes {
  NORMAL = 0,
  ABNORMAL = 1,
  ALARM = 2,
  ALL = 3,
  MAINTAIN = 4,
}

export const ALARM_STATUS = {
  '-1': '斷線',
  1: '正常',
  6: '白天亮燈標記',
  7: '白天亮燈警報',
  8: '白天亮燈故障',
  9: '夜間離線標記',
  10: '夜間離線警報',
  11: '夜間離線故障',
  12: '異常訊號標記',
  13: '異常訊號警報',
  14: '異常訊號故障',
  100: '未知狀態',
}

export const alarmFragment = gql`
  fragment alarmInfo on Alarm {
    id
    deviceId
    address
    isAlarm
    isDeviceFail
    isRepair
    isSmartControl
    lat
    lightNo
    lampWatt
    lampStatus
    lon
    updateTime
  }
`

export const getAlarmsQuery = gql`
  query getAlarms($params: Input!) {
    results(params: $params)
      @rest(
        type: "[Alarm]"
        endpoint: "dashboard"
        path: "/api/v1/device/deviceInfos?{args.params}"
      ) {
      code
      totalPages
      data @type(name: "Alarm") {
        ...alarmInfo
      }
    }
  }
  ${alarmFragment}
`

export const convertData = (data?: QueryResult) => {
  const dataSource = convertAPIData<Alarm>({
    data,
    field: 'data',
    converter: x => ({
      ...x,
      lightType: x.isSmartControl ? '智能路燈' : '傳統路燈',
      alarmType: x.isDeviceFail ? AlarmModes.ABNORMAL : AlarmModes.ALARM,
      remark: x.isRepair ? x.remark : `警報:${ALARM_STATUS[x.lampStatus]}`,
      updateTime: toMoment(x.updateTime),
      updateTimeStr: momentToString(x.updateTime, 'YYYY.MM.DD HH:mm'),
      light: x,
    }),
  })

  return dataSource
}

export const useBrokenDevices = (variables?: ApolloQueryVariables) => {
  const { waitForDispatchState, withDispatchedState } = useReportStates()

  const { queryRepairs, data, loading, ...others } = useQueryRepairs(variables)

  return {
    queryBrokenDevices: (variables: { [key: string]: any }) =>
      queryRepairs({
        variables: {
          params: {
            hasLight: true,
            pageNum: 1,
            pageSize: 100,
            state: compose(
              join(','),
              pluck('state')
            )([waitForDispatchState, withDispatchedState]),
            ...variables,
          },
        },
      }),
    loading,
    data,
    loadingDevices: loading,
    ...others,
  }
}

export const useAlarms = (variables?: ApolloQueryVariables) => {
  const [queryAlarms, { data, loading, ...others }] = useLazyQuery<QueryResult>(
    getAlarmsQuery,
    {
      ...variables,
    }
  )

  return {
    queryAlarms: (variables: { [key: string]: any }) =>
      queryAlarms({
        variables: {
          params: { isAlarm: true, isDeviceFail: false, ...variables },
        },
      }),
    loading,
    data,
    loadingAlarms: loading,
    ...others,
  }
}
