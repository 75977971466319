// import { typePatcher } from 'apollo-type-patcher'
// import { Repair } from './maintain'
// import { StreetLight } from './streetLight'

export default {
  Repair: {
    data: {
      light: 'StreetLight',
      worker: 'Worker',
    },
  },
  RepairLight: {
    data: {
      info: 'StreetLight',
    },
  },
}
