import { css } from 'styled-components'
import { normalize } from 'polished'

export const utils = css`
  .text-danger {
    color: #f5222d !important;
  }

  .text-largest {
    font-size: 18px !important;
  }

  .text-larger {
    font-size: 16px !important;
  }

  .text--default {
    font-size: 14px !important;
  }

  .text--smaller {
    font-size: 13px;
  }

  .text-sm {
    font-size: 12px !important;
  }

  .text-xs {
    font-size: 10px;
  }

  .text-500 {
    font-weight: 500 !important;
  }

  .text-center {
    text-align: center;
  }

  .text-left {
    text-align: left !important;
  }

  .text-right {
    text-align: right;
  }

  .text-underline {
    text-decoration: underline;
  }

  .text-ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .text-white {
    color: #fff !important;
  }

  .bg-light {
    background: #fffcf1;
  }

  .bg-white {
    background: #fff;
  }

  .flex-1 {
    flex: 1;
  }

  .pull-left {
    float: left;
  }

  .pull-right {
    float: right;
  }

  .width--full {
    width: 100%;
  }

  .p--relative {
    position: relative;
  }

  .p--absolute {
    position: absolute;
  }

  .opacity--half {
    opacity: 0.5;
  }

  &.is--scrollbar-none {
    ::-webkit-scrollbar {
      display: none;
    }
  }

  .cursor--pointer {
    cursor: pointer;
  }
`

export const layout = css`
  .l-flex {
    display: flex !important;

    &.is--inline {
      display: inline-flex !important;
    }

    &--center {
      display: flex;
      align-items: center;
    }

    &.is--stretch {
      align-items: stretch;
    }

    &.is--center {
      justify-content: center;
    }

    &.is--space-between {
      justify-content: space-between;
    }

    &.is--right {
      justify-content: flex-end;
    }

    &.is--middle {
      align-items: center;
    }

    &.is--space-between {
      justify-content: space-between;
    }

    &.is--column {
      flex-direction: column;
    }
  }

  .d-block {
    display: block !important;
  }

  .d-inline-block {
    display: inline-block;
  }
`

export const components = css`
  .c-datepicker {
    .ant-input,
    .ant-input:hover,
    .ant-input:focus,
    &.ant-calendar-picker:hover
      .ant-calendar-picker-input:not(.ant-input-disabled) {
      border-color: ${p => p.theme.gray88};
      box-shadow: none;
    }

    .anticon-calendar {
      color: ${p => p.theme.calendarIcon};
    }
  }

  .c-btn.ant-btn {
    border-radius: 3px;
    font-weight: 500;
  }

  .c-btn--primary.ant-btn {
    color: ${p => p.theme.btn.text};
    border-color: ${p => p.theme.btn.bg};
    background: ${p => p.theme.btn.bg};

    &:hover,
    &:focus,
    &:active {
      color: ${p => p.theme.btn.text};
      background: ${p => p.theme.btn.bgHover};
      border-color: ${p => p.theme.btn.bgHover};
    }
  }

  .c-btn--outline.ant-btn {
    color: ${p => p.theme.btn.bg};
    border: 1.3px solid ${p => p.theme.btn.bg};
    background: #fff;

    &:hover,
    &:focus,
    &:active {
      color: ${p => p.theme.btn.bg};
      background: ${p => p.theme.btn.outlineHover};
      border: 1.3px solid ${p => p.theme.btn.bg};
    }
  }

  .c-btn--danger.ant-btn {
    color: ${p => p.theme.danger};
    border: 1.3px solid ${p => p.theme.danger};
    background: #fff;

    &:hover,
    &:focus,
    &:active {
      color: ${p => p.theme.danger};
      background: ${p => p.theme.lightPink};
      border: 1.3px solid ${p => p.theme.danger};
    }
  }

  &.c-btn--link {
    background: #fff;
    border: transparent;
  }

  .c-icon {
    &.is--small,
    &.icon-loading {
      svg {
        height: 16px;
        width: 16px;
      }
    }

    &.icon-minus-circle {
      svg {
        fill: ${p => p.theme.icon.primary};
      }

      :hover {
        svg {
          fill: ${p => p.theme.icon.primaryHover};
        }
      }
    }

    &.icon-location,
    &.icon-eye,
    &.icon-edit,
    &.icon-process,
    &.icon-info {
      cursor: pointer;

      > svg > g path:nth-child(2),
      > svg > g path:nth-child(2),
      svg use {
        fill: ${p => p.theme.icon.primary};
      }
    }

    &.icon-location:hover,
    &.icon-process:hover {
      svg > g > path:nth-child(2) {
        fill: ${p => p.theme.icon.primaryHover};
      }
    }

    &.icon-eye:hover,
    &.icon-edit:hover {
      svg use {
        fill: ${p => p.theme.icon.primaryHover};
      }
    }

    &.icon-info:hover {
      svg > g path:nth-child(3) {
        fill: ${p => p.theme.icon.primaryHover};
      }
    }

    &.anticon:hover {
      svg {
        fill: ${p => p.theme.icon.primaryHover};
      }
    }

    &.is--secondary {
      svg {
        height: 16px;
        width: 16px;
      }

      &.icon-add > svg > g path:nth-child(2),
      &.icon-export > svg > g path:nth-child(2),
      &.icon-eye svg use {
        fill: ${p => p.theme.icon.secondary};
      }

      svg g path:nth-child(3) {
        fill: ${p => p.theme.icon.secondary};
      }
    }
  }

  .c-link {
    color: #00a0e8;
    text-decoration: underline;
    cursor: pointer;

    &:hover {
      color: #00a0e8;
    }
  }

  .c-select__control {
    min-height: 30px !important;
    line-height: 0;
    box-shadow: none !important;
    border-color: #d9d9d9 !important;
    cursor: pointer !important;

    &:focus,
    &:hover {
      border-color: ${p => p.theme.primary} !important;
    }

    .anticon svg {
      transition: transform 0.3s;
    }
  }

  .c-select__control--menu-is-open {
    .anticon svg {
      transform: rotate(180deg);
    }
  }

  .c-select__indicators {
    margin-right: -3px;
  }

  .c-select__indicator {
    color: hsl(0, 0%, 80%);

    svg {
      margin-right: 2px;
      height: 18px;
      width: 18px;
    }
  }

  .c-select__indicator-separator {
    width: 0 !important;
  }

  .c-select__placeholder {
    color: #bfbfbf !important;
  }

  .c-select__value-container {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .c-select__menu {
    z-index: 2 !important;
    margin-top: 2px !important;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15) !important;
  }

  .c-select__menu-list {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .c-select__single-value {
    color: rgba(0, 0, 0, 0.65) !important;
    overflow: visible !important;
  }

  .c-select__option {
    padding-top: 5.5px !important;
    padding-bottom: 5.5px !important;
    line-height: 21px;
    cursor: pointer !important;
  }

  .c-select__option:not(&.c-select__option--is-focused) {
    background-color: #fff !important;
    color: rgba(0, 0, 0, 0.65) !important;
  }

  .c-select__option--is-focused {
    background-color: ${p => p.theme.primary} !important;
    color: rgba(0, 0, 0, 0.65) !important;
  }

  div[class*='indicatorContainer'] {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    padding-left: 0 !important;
  }
`

export const antdOverride = css`
  @media (max-width: 575px) {
    .ant-form-item-label,
    .ant-form-item-control-wrapper {
      width: auto !important;
    }
  }

  .ant-calendar-date:hover {
    color: #fff !important;
    background: ${p => p.theme.primary} !important;
  }

  .ant-calendar-header a:hover {
    color: ${p => p.theme.primary} !important;
  }

  .ant-calendar-today .ant-calendar-date {
    color: ${p => p.theme.darkGrey} !important;
    border-color: ${p => p.theme.primary} !important;
  }

  .ant-calendar-today .ant-calendar-date:hover {
    color: #fff !important;
    border-color: ${p => p.theme.primary} !important;
  }

  .ant-calendar-selected-day .ant-calendar-date {
    background: ${p => p.theme.primary} !important;
  }

  .ant-calendar-today-btn {
    color: ${p => p.theme.dark};

    &:hover {
      color: ${p => p.theme.darkGrey};
    }
  }

  .ant-calendar-range .ant-calendar-in-range-cell > .ant-calendar-date {
    color: #00284d !important;
  }

  .ant-calendar-range .ant-calendar-selected-start-date .ant-calendar-date {
    color: #fff !important;
  }

  .ant-select-dropdown-menu-item {
    transition: none !important;
  }

  .ant-calendar-selected-date .ant-calendar-date {
    background: ${p => p.theme.primary} !important;
    color: ${p => p.theme.darkGrey} !important;
  }

  .ant-calendar-range .ant-calendar-in-range-cell::before {
    background: rgb(195, 221, 244) !important;
  }

  .ant-select-dropdown-menu-item-active {
    background: ${p => p.theme.primary} !important;
    color: #fff !important;
  }

  .ant-message-notice-content {
    box-shadow: none !important;
  }

  .ant-icon-is-hoverable {
    cursor: pointer;

    &:hover {
      color: ${p => p.theme.secondary};
    }
  }

  .ant-toolitp-content {
    font-weight: 500 !important;
  }

  .ant-tooltip-arrow:before {
    background: ${p => p.theme.tooltip.bg} !important;
  }

  .ant-tooltip-inner {
    background: ${p => p.theme.tooltip.bg} !important;
    border: 1px solid ${p => p.theme.tooltip.bg} !important;
    color: ${p => p.theme.tooltip.color} !important;
  }

  .ant-modal {
    animation-duration: 0s !important;
    transition: none !important;
  }

  .ant-spin-dot-item {
    background: ${p => p.theme.primary} !important;
  }

  .ant-spin-text {
    color: ${p => p.theme.yellow} !important;
  }

  .ant-modal-confirm .ant-modal-confirm-btns {
    margin-top: 32px !important;
  }

  .ant-select-dropdown-menu-item.menu-item {
    color: unset;
    padding: 4px 8px !important;
    text-align: center;
  }

  .ant-select-dropdown-menu-item.menu-item.ant-select-dropdown-menu-item-active {
    background-color: #5e9ce5 !important;
  }
`

export const antCustomOverride = css`
  .ant-select-dropdown-menu-item {
    padding: 6px 23px !important;
  }
`

export const googleMapsOverride = css`
  .gm-style-cc {
    display: none;
  }

  .gm-control-active.gm-fullscreen-control {
    bottom: 120px !important;
    left: 17px !important;
  }

  .gmnoprint.gm-bundled-control.gm-bundled-control-on-bottom {
    div:nth-child(2) {
      display: none;
    }
    .gm-control-active {
      width: 28px !important;
      height: 28px !important;
      border-radius: 4px;
      box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2);
      background-color: #f4f3f3 !important;
      img {
        display: none !important;
      }
      &:first-child:after {
        content: '+';
        vertical-align: middle;
        display: inline-block;
        text-align: center;
        font-size: 32px;
        color: #003248;
        line-height: 0;
      }
      &:last-child {
        &:after {
          content: '';
          vertical-align: middle;
          display: inline-block;
          width: 17px;
          height: 1px;
          border-top: solid 3px #003248;
          text-align: center;
        }
      }
    }
  }

  .gm-style .gm-style-iw-c {
    padding: 0 !important;
    border-radius: 0 !important;
    background-color: transparent;
    box-shadow: unset;
  }

  .gm-ui-hover-effect {
    visibility: hidden !important;
  }

  .gm-style a[href^="https://maps.google.com/maps"]
  {
    display: none !important;
  }

  .gm-style .gm-style-iw-d {
    overflow: unset !important;
  }

  .gm-style .gm-style-iw-c {
    padding-top: 32px;
    padding-left: 0 !important;
  }

  .gm-style .gm-style-iw button > img {
    width: 18px !important;
    height: 18px !important;
    position: relative;
    right: 10px;
    top: 5px;
  }

  .gm-style .gm-style-iw-t::after {
    display: none;
    // visibility: visible;
    // transform: translate(-713%, -1368%) rotate(46deg) !important;
    // border-left: 1px solid #0074a8;
    // border-bottom: 1px solid #0074a8;
    // background: #fff;
  }

  .gm-bundled-control {
    margin: 0 !important;
    bottom: 100px !important;
    left: 24px !important;

    > .gmnoprint > div {
      height: auto !important;
      background: transparent !important;
      box-shadow: none !important;
      cursor: default !important;
    }

    .gm-control-active {
      border-radius: 4px;
      box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2);
      background-color: #f4f3f3 !important;
    }

    .gm-control-active[title='Zoom in'] + div {
      display: none;
    }

    .gm-control-active[title='Zoom out'] {
      top: 16px !important;
    }
  }

  .infoBox > img {
    display: none;
  }

  .infoBox:before {
    content: ' ';
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid #fff;
    position: absolute;
    top: -10px;
    left: 130px;
  }

  .google-map-marker-with-label--label {
    width: 54px;
    height: 17px;
    padding-top: 2px;
    background: #e4e4e4;
    border-radius: 3px;
    text-align: center;
    color: ${p => p.theme.darkGrey};
  }
`

export default css`
  ${normalize};
  ${utils};
  ${layout};
  ${components};
  ${googleMapsOverride}
  ${antdOverride};
  ${antCustomOverride};
`
