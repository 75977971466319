import React from 'react'
import { Input } from 'shared/components/form/Input'

const PickerInput = ({
  onClick,
  extra,
  className,
  ...props
}: {
  onClick?: (evt: React.MouseEvent<HTMLElement>) => any
  placeholder?: string
  className?: string
  extra?: any
} = {}) => {
  const _extra = extra === '请选择' ? null : extra

  return (
    <Input
      readOnly
      onClick={onClick}
      value={_extra}
      className={className}
      {...props}
    />
  )
}

export default PickerInput
