import React from 'react'
import { Icon, Divider } from 'antd'
import { useVillages } from '@/graphql/sessions'
import { Select } from './Select'
import { GetFieldDecorator } from './types'

export type DistrictProps = {
  getFieldDecorator?: GetFieldDecorator
  labelCol?: number // FIXME: remove
  wrapperCol?: number // FIXME: remove
  onDistChange?: (id: string) => void
  onChange?: (id: number) => void
  style?: React.CSSProperties // FIXME: remove
  distFieldName?: string
  useVillage?: boolean
  useDist?: boolean
  distClassName?: string
  label?: boolean // FIXME: remove
  address?: boolean
  addressClassName?: string
  size?: 'default' | 'small' | 'large'
  customizeProps?: (props: DistrictProps) => DistrictProps
  onEditModalVisible?: () => void
  newItem?: object //TODO
  disabled?: boolean
  options?: object
  placeholder?: string
}

export const District = React.forwardRef<typeof Select, DistrictProps>(
  (
    {
      getFieldDecorator,
      onDistChange,
      onChange,
      distFieldName = 'sessionName',
      useDist = true,
      label = false,
      size = 'default',
      // customizeProps,
      distClassName,
      onEditModalVisible,
      newItem,
      disabled = false,
      options,
      placeholder = '選擇區域',
      style,
    }: DistrictProps,
    ref
  ): any => {
    const { distEnums, loading } = useVillages()

    let wrapper = React.useMemo(
      () =>
        getFieldDecorator
          ? getFieldDecorator
          : (field: string) => (node: React.ReactNode) => node,
      [getFieldDecorator]
    )
    //新增區域
    // React.useEffect(() => {
    //   if (newItem) {
    //     console.log('newItem', newItem)
    //   }
    // }, [newItem])

    const handleChange = (value: any) => {
      if (onChange) {
        onChange(value)
      }
      if (onDistChange) {
        onDistChange(value)
      }
    }

    return (
      <>
        {useDist &&
          wrapper(
            distFieldName,
            options
          )(
            <Select
              disabled={disabled}
              forwardRef={ref}
              loading={loading}
              style={style}
              className={distClassName}
              placeholder={placeholder}
              allowClear
              size={size}
              onChange={handleChange}
              dropdownRender={menu => (
                <div>
                  {menu}
                  {onEditModalVisible && (
                    <>
                      <Divider style={{ margin: '4px 0' }} />
                      <div
                        style={{ padding: '0px 5px 4px', cursor: 'pointer' }}
                        onClick={() => onEditModalVisible()}
                        onMouseDown={e => e.preventDefault()}>
                        <Icon type="plus" /> 新增區域
                      </div>
                    </>
                  )}
                </div>
              )}>
              {distEnums.map(x => (
                <Select.Option key={x.sessionNo} value={x.sessionNo}>
                  {x.sessionName}
                </Select.Option>
              ))}
            </Select>
          )}
      </>
    )
  }
)

export default District
