import React from "react";
import styled, { css } from "styled-components";
import { Form } from "antd";
import { FormProps } from "antd/lib/form";

type Props = FormProps & {
  type?: string;
};

const StyledForm = styled(Form)<Props>`
  &.ant-form {
    &:not(.is--normal-form) .ant-form-item-label {
      text-align: left;
    }

    &:not(.is--normal-form) .ant-form-item-text,
    &:not(.is--normal-form) label {
      font-size: 16px;
      font-weight: normal;
      color: ${(p) => p.theme.block.title};
    }

    ${(p) =>
      p.type === "largest" &&
      css`
        &:not(.is--normal-form) .ant-form-item-text,
        &:not(.is--normal-form) label {
          font-size: 18px;
          font-weight: 500;
        }
      `};

    .ant-form-item-children {
      font-size: 16px;
      font-weight: 500;
      color: ${(p) => p.theme.block.title};

      input {
        font-size: 16px;
        font-weight: 500;
        color: ${(p) => p.theme.block.title};

        ::placeholder {
          font-weight: normal;
        }
      }

      .ant-select-selection__placeholder {
        font-weight: normal;
      }

      .ant-select-selection__choice {
        font-weight: normal;
        color: ${(p) => p.theme.block.title};
      }
    }

    .form-item--district {
      .ant-form-item-control-wrapper {
        margin-left: -3px;
      }
    }

    &.is--inline {
      .ant-form-item {
        margin-bottom: 0;
      }
    }

    &.form--align {
      .input__address {
        position: relative !important;
        top: 4px !important;
      }
    }
  }
`;

const FormItem = styled(Form.Item).attrs({
  colon: false,
})`
  &.ant-form-item {
    margin-bottom: 8px;
  }

  &.ant-form-item.is--inline {
    line-height: unset;
    margin-bottom: 0;

    .ant-form-item-control {
      line-height: unset;
    }
  }

  .ant-form-item-no-colon.ant-form-item-required::before {
    display: none;
  }
`;

const FormHideRequiredMask = styled(Form.Item).attrs({
  // hideRequiredMark: true,
})``;

class FormComponent extends React.Component<Props, any> {
  public static create = Form.create;
  public static Item = FormHideRequiredMask;

  public render() {
    return <StyledForm {...this.props}>{this.props.children}</StyledForm>;
  }
}

export { FormComponent as Form, FormItem };
