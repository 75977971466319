import React from 'react'
import gql from 'graphql-tag'
import { useQuery } from '@apollo/react-hooks'
import { forEach } from 'ramda'

export type RepairEnum = {
  key: string
  name: string
  type: number
  data: {
    value: string
    desc: string
  }[]
}

export type QueryRepairEnum = {
  results: { data: RepairEnum[] }
}

export const getRepairEnumQuery = gql`
  query getBrokenReasons {
    results @rest(type: "RepairEnum", path: "/options/repair/static") {
      data @type(name: "RepairEnum") {
        key
        name
        type
        data
      }
    }
  }
`

export const useRepairEnum = () => {
  const { data, loading, error } = useQuery<QueryRepairEnum>(
    getRepairEnumQuery,
    {
      fetchPolicy: 'cache-first',
    }
  )

  const { repairStateEnumByKey, brokenReasonEnumByKey } = React.useMemo(() => {
    let repairStateEnumByKey: { [key: string]: string } = {}
    let brokenReasonEnumByKey: { [key: string]: string } = {}

    if (data) {
      let repairEnum: { [key: string]: RepairEnum } = {}
      forEach((x: RepairEnum) => (repairEnum[x.key] = x), data.results.data)

      forEach(
        x => (repairStateEnumByKey[x.value] = x.desc),
        repairEnum['RepairState'].data
      )

      forEach(
        x => (brokenReasonEnumByKey[x.value] = x.desc),
        repairEnum['BrokenReason'].data
      )
    }

    return {
      brokenReasonEnumByKey,
      repairStateEnumByKey,
    }
  }, [data])

  return {
    brokenReasonEnumByKey,
    repairStateEnumByKey,
    repairEnum: data?.results.data as RepairEnum[],
    loading,
    error,
  }
}

export default useRepairEnum
