import React from 'react'
import { forEach } from 'ramda'
import {
  DataSource,
  Marker,
} from 'shared/constants/types'

type ContextType = {
  map: google.maps.Map | null
  handleMapLoad: (map: google.maps.Map) => any,
  dataSource: DataSource<Marker>,
  setDataSource: (value: any) => ({}),
  handleRemoveAll: () => any,
  search: any,
  setSearch: (value: any) => ({})
}

const initialDataSource = {
  content: [],
  total: 0,
  totalPages: 0,
}

const MapContext = React.createContext<ContextType>({
  map: null,
  handleMapLoad: (map: google.maps.Map) => ({}),
  dataSource: initialDataSource,
  setDataSource: (value: any) => ({}),
  handleRemoveAll: () => ({}),
  search: {},
  setSearch: (value: any) => ({})
})

export function GoogleMapProvider(props: any) {
  const [map, setMap] = React.useState<google.maps.Map | null>(null)
  const [dataSource, setDataSource] = React.useState<DataSource<Marker>>(initialDataSource)
  const [search, setSearch] = React.useState<any>({})

  const handleMapLoad = React.useCallback((mapInstance: google.maps.Map) => {
    setMap(mapInstance)
  }, [])

  const handleRemoveAll = React.useCallback(() => {
    forEach(x => x.marker.setMap(null), dataSource.content)
  }, [dataSource.content])

  let value = React.useMemo(
    () => ({
      map,
      handleMapLoad,
      dataSource,
      setDataSource,
      handleRemoveAll,
      setSearch,
      search
    }),
    [map, handleMapLoad, dataSource, search]
  )

  return <MapContext.Provider value={value} {...props} />
}

export function useGoogleMap() {
  const context = React.useContext(MapContext)
  if (!context) {
    throw new Error('useGoogleMap must be within a MapProvider')
  }

  return context
}

export default useGoogleMap
