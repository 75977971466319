import React from 'react'
import { Picker } from 'antd-mobile'
import { map } from 'ramda'
import { skipState } from '@/graphql/utils'
import { PickerProps } from '@/constants/types'
import useFieldDecorator from '@/hooks/useFieldDecorator'
import { Select, SelectProps } from '@/components/form'
import PickerInput from '@/components/common/PickerInput'
const { compose } = require('ramda')
import { useRepairEnum } from './repair'

const skip = {
  name: 'state',
  field: 'code',
}

type MaintainState = {
  code: string //RS00
  state: string //未派工
}

export const useReportStates = () => {
  const reportStates: MaintainState[] = []
  const { repairStateEnumByKey, loading } = useRepairEnum()

  Object.keys(repairStateEnumByKey).map(i =>
    reportStates.push({ code: i, state: repairStateEnumByKey[i] })
  )

  // --> 將所有案件狀態統計註記在這裡，避免判斷狀態的邏輯分散各處

  // RS00 未派工
  const waitForDispatchState = skipState({
    name: 'code',
    field: 'state',
  })(reportStates)

  // RS02 已派工
  const withDispatchedState = skipState({ ...skip, value: '已派工' })(
    reportStates
  )

  // RS03 結案
  const withFinishedState = skipState({ ...skip, value: '結案' })(reportStates)

  // RS99 --> RS01/RS00
  const withUndispatchState = skipState<MaintainState>({
    ...skip,
    value: '未派工',
  })(reportStates)

  const isWaitForDispatch = React.useCallback((state: string) => {
    return state === 'RS00' || state === 'RS01' || state === 'RS99'
  }, [])

  // RS04
  const changeDispatchState = skipState({
    ...skip,
    name: 'remark',
    value: '改派工',
  })(reportStates)

  return {
    reportStateEnums: reportStates as MaintainState[],
    changeDispatchState,
    isWaitForDispatch,
    reportStateById: repairStateEnumByKey,
    waitForDispatchState,
    withDispatchedState,
    withFinishedState,
    withUndispatchState,
    loading,
  }
}

export const ReportStateSelect: React.ElementType<SelectProps> = React.forwardRef<
  typeof Select,
  SelectProps
>(
  (
    { getFieldDecorator, initialValue, render, ...props }: SelectProps,
    ref
  ): any => {
    const { repairStateEnumByKey } = useRepairEnum()

    let wrapper = useFieldDecorator({
      field: 'state',
      getFieldDecorator,
      initialValue,
    })

    return wrapper(
      <Select forwardRef={ref} allowClear placeholder="案件狀態" {...props}>
        {Object.keys(repairStateEnumByKey).map(i => (
          <Select.Option key={i} value={i}>
            {repairStateEnumByKey[i]}
          </Select.Option>
        ))}
      </Select>
    )
  }
)

export const ReportStateMobileSelect: React.ElementType<PickerProps> = React.forwardRef<
  typeof Picker,
  PickerProps
>(
  (
    { getFieldDecorator, initialValue, render, ...others }: PickerProps,
    ref
  ): any => {
    const { reportStateEnums } = useReportStates()

    let wrapper = useFieldDecorator({
      field: 'reportstate',
      getFieldDecorator,
      initialValue,
    })

    const filter = (values: { label: string; value: string }[]) => {
      return render ? render(values) : values
    }

    const props = {
      forwardRef: ref,
      data: compose(
        filter,
        map((x: MaintainState) => ({ label: x.state, value: x.code }))
      )(reportStateEnums),
      ...others,
    }

    return wrapper(
      <Picker
        {...props}
        cols={1}
        dismissText="取消"
        okText="確定"
        title="請選擇">
        <PickerInput placeholder="選擇案件狀態" />
      </Picker>
    )
  }
)

export default useReportStates
