import { pathOr } from 'ramda'
import history from 'shared/utils/history'
import { UserPermission } from 'shared/graphql/auth'
import { ActionTypes } from 'shared/constants/enums'
import useAuth from './useAuth'

export default function useRouteCRUDAuthCheck() {
  const { user } = useAuth()

  const routeAuth = pathOr(
    {},
    ['authByUrl', history.location.pathname],
    user
  ) as UserPermission

  // return [routeAuth, (actionType: ActionTypes) => routeAuth[actionType]] as [
  //   UserPermission,
  //   (act: string) => boolean
  // ]
  return [routeAuth, (actionType: ActionTypes) => true] as [
    UserPermission,
    (act: string) => boolean
  ]
}
